import { Box, Chip, Typography, useMediaQuery } from "@mui/material";
import { useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import dayjs, { Dayjs } from "dayjs";
import { Tables } from "src/utils/DatabaseDefinitions";
import { complianceStepColors } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";

export type AthleteComponentProps = {
  name: string;
  image?: string;
  user_id: string;
  max_heart_rate_session_id: string;
  max_power_session_id: string;
  athlete_experience_level: {
    experience_level: string;
  };
  session: {
    date: string;
    total_time: number
    tss: number
  }[];
  assigned_workout: Tables<'assigned_workout'>[];
  calendarDate: Dayjs
};

export default function CoachAthlComponentDashboard(
  props: AthleteComponentProps,
) {
  const supabase = useSupabase();

  const avatar = useMemo(() => {
    if(props.image) {
      return supabase.storage.from('avatars').getPublicUrl(props.image, {
        transform: {
          width: 50,
          height: 50,
        },
      }).data.publicUrl
    }
    return createAvatar(initials, {
      size: 20,
      radius: 50,
      seed: props.name,
    }).toDataUriSync();
  }, [props]);

  const isMobileScreen = useMediaQuery("(max-width:920px)");
  
  const thisWeekCompliance = useMemo(() => {
    const now = dayjs()
    const compliance = [];
    let daysWithWorkout = 0;
    let targetMeetDays = 0;

    for (let i = 0; i < 7 ; i++) {
      const dayWorkouts = props.assigned_workout?.filter((workout) => dayjs(workout.date).isSame(props.calendarDate.add(i, 'day'), "day"))
      if(dayWorkouts?.length === 0) {
        compliance[props.calendarDate.add(i, 'day').format("YYYY-MM-DD")] = {
          date: props.calendarDate.add(i, 'day').format("YYYY-MM-DD"),
          status: "nothing"
        };
        continue;
      }
      if (now < props.calendarDate.add(i, 'day')) {
        compliance[props.calendarDate.add(i, 'day').format("YYYY-MM-DD")] = {
          date: props.calendarDate.add(i, 'day').format("YYYY-MM-DD"),
          status: "todo"
        };
        continue;
      }
      const targetWorkoutInfo = dayWorkouts?.reduce((acc,curr) => ({duration: acc.duration + curr.duration, tss: acc.tss + curr.tss}),{duration: 0, tss: 0})
      const daySessionInfo = props.session?.reduce((acc,curr) => {  
        if (dayjs(curr.date).isSame(props.calendarDate.add(i, 'day'), "day")) {
          return {
            duration: acc.duration + (curr.total_time / 1000),
            tss: acc.tss + curr.tss
          }
        }
        return acc
      }, {duration: 0, tss: 0});
      if(daySessionInfo?.duration / targetWorkoutInfo?.duration >= 0.75 && daySessionInfo.tss * (1 / targetWorkoutInfo.tss) >= 0.75) {
        compliance[props.calendarDate.add(i, 'day').format("YYYY-MM-DD")] = {
          date: props.calendarDate.add(i, 'day').format("YYYY-MM-DD"),
          status: "target meet"
        };
        daysWithWorkout += 1
        targetMeetDays += 1
        continue;
      }
      compliance[props.calendarDate.add(i, 'day').format("YYYY-MM-DD")] = {
        date: props.calendarDate.add(i, 'day').format("YYYY-MM-DD"),
        status: "skipped"
      };
      daysWithWorkout += 1
      continue;
    }
    const complianceArray = Object.values(compliance);
    return {complianceArray, targetMeetDays, daysWithWorkout};
  }, [props]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        padding: 0.8,
        borderRadius: 5,
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img src={avatar} alt="avatar" style={{borderRadius: '50%', width: '40px', height: '40px', objectFit: 'cover'}}/>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant={isMobileScreen ? "h5" : "h4"}>
            {props.name}
          </Typography>
          <Chip
            label={props.athlete_experience_level?.experience_level}
            size="small"
            sx={{
              backgroundColor:
                props.athlete_experience_level?.experience_level === "Beginner"
                  ? "#F89F5B"
                  : props.athlete_experience_level?.experience_level ===
                    "Intermediate"
                  ? "#E53F71"
                  : props.athlete_experience_level?.experience_level ===
                    "Advanced"
                  ? "#9C3587"
                  : "purple",
              height: "18px",
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>COMPLIANCE</Typography>
          <Typography>{thisWeekCompliance.daysWithWorkout === 0 ? '-' : (thisWeekCompliance.targetMeetDays / thisWeekCompliance.daysWithWorkout * 100).toFixed(1)} %</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          {thisWeekCompliance.complianceArray.map((day, index) => (
            <Box
              key={index}
              sx={{
                background: complianceStepColors[day.status],
                width: "14%",
                height: "9px",
                margin: "1px",
              }}
            ></Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

import { Box, Typography, useMediaQuery } from "@mui/material";
import LockScreenTrendCard from "./LockScreenTrendCard";
import PremiumLogo from "../../assets/img/views/lock/premiumVersionLogo.svg";

export type TrendsCardProps = {
  index: number;
  icon: JSX.Element;
  metric: string | number;
  title: string;
  description: string;
  isActive: boolean;
  onCardClick: () => void;
  isPremium: boolean;
};

export default function TrendsCard(props: TrendsCardProps) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      {!props.isPremium && (props.index === 8 || props.index === 9) ? (
        <LockScreenTrendCard
          title={props.index === 8 ? "Position Score" : "Speed efficiency"}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "#2C262D",
            width: isMobileScreen ? "98%" : "30%",
            padding: { md: "1.5em", sm: "1em", xs: "1em" },
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            border: props.isActive ? "2px solid #BC6D29" : "",
            position: "relative",
          }}
          onClick={props.onCardClick}
        >
          {(props.index === 8 || props.index === 9) && (
            <img
              src={PremiumLogo}
              alt="lock-logo"
              height="28px"
              width="28px"
              style={{
                zIndex: 1,
                position: "absolute",
                top: -13,
                right: -13,
              }}
            />
          )}

          <Box sx={{ alignSelf: "flex-end" }}>{props.icon}</Box>

          <Typography
            sx={{
              color: "#BC6D29",
              fontSize: ".9375rem",
              fontWeight: 700,
            }}
          >
            {props.title === "Normalised Power®"
              ? `${Number(props.metric).toFixed(0)} W`
              : props.metric}
          </Typography>
          <Typography
            sx={{
              fontSize: ".6875rem",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{
              color: "#A19D9A",
              fontSize: ".5rem",
            }}
          >
            {`${props.description.split(".")[0]}.`}
          </Typography>
        </Box>
      )}
    </>
  );
}

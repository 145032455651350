import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
} from "@mui/material";
import HeartIcon from "../../../assets/img/layout/coach/MyAthletes/HeartIcon.svg";
import PowerIcon from "../../../assets/img/layout/coach/MyAthletes/PowerIcon.svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import dayjs from "dayjs";
import type { Tables } from "src/utils/DatabaseDefinitions";
import { complianceStepColors, type SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import close from "../../../assets/img/views/settings/Close.svg";
import { useUser } from "src/components/Authenticated";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import { useSnackbar } from "notistack";

export type AthleteComponentProps = {
	image?: string;
	name: string;
	user_id: string;
	max_heart_rate_session_id: string;
	max_power_session_id: string;
	athlete_experience_level: {
		experience_level: string;
	};
	session: {
		date: string;
		total_time: number;
		tss: number;
	}[];
	assigned_workout: Tables<"assigned_workout">[];
};

export default function CoachAthleteComponent(props: AthleteComponentProps) {
	const supabase = useSupabase();
	const navigation = useNavigate();
	const user = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const [deleteDialog, setDeleteDialog] = useState(false);

	const handleNavigation = useCallback(
		(sessionId: string, event: React.MouseEvent) => {
			event.preventDefault();
			navigation(`/coach/my_athletes/session/${sessionId}`);
		},
		[],
	);

	const avatar = useMemo(() => {
		if (props.image) {
			return supabase.storage.from("avatars").getPublicUrl(props.image, {
				transform: {
					width: 50,
					height: 50,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 50,
			radius: 55,
			seed: props.name,
		}).toDataUriSync();
	}, [props]);

	const thisWeekCompliance = useMemo(() => {
		const now = dayjs();
		const startOfWeek = now.startOf("week").add(1, "day");
		const compliance = [];
		let daysWithWorkout = 0;
		let targetMeetDays = 0;

		for (let i = 0; i < 7; i++) {
			const dayWorkouts = props.assigned_workout?.filter((workout) =>
				dayjs(workout.date).isSame(startOfWeek.add(i, "day"), "day"),
			);
			if (dayWorkouts?.length === 0) {
				compliance[startOfWeek.add(i, "day").format("YYYY-MM-DD")] = {
					date: startOfWeek.add(i, "day").format("YYYY-MM-DD"),
					status: "nothing",
				};
				continue;
			}
			if (now < startOfWeek.add(i, "day")) {
				compliance[startOfWeek.add(i, "day").format("YYYY-MM-DD")] = {
					date: startOfWeek.add(i, "day").format("YYYY-MM-DD"),
					status: "todo",
				};
				continue;
			}
			const targetWorkoutInfo = dayWorkouts?.reduce(
				(acc, curr) => ({
					duration: acc.duration + curr.duration,
					tss: acc.tss + curr.tss,
				}),
				{ duration: 0, tss: 0 },
			);
			const daySessionInfo = props.session?.reduce(
				(acc, curr) => {
					if (dayjs(curr.date).isSame(startOfWeek.add(i, "day"), "day")) {
						return {
							duration: acc.duration + curr.total_time / 1000,
							tss: acc.tss + curr.tss,
						};
					}
					return acc;
				},
				{ duration: 0, tss: 0 },
			);
			if (
				daySessionInfo?.duration / targetWorkoutInfo?.duration >= 0.75 &&
				daySessionInfo.tss * (1 / targetWorkoutInfo.tss) >= 0.75
			) {
				compliance[startOfWeek.add(i, "day").format("YYYY-MM-DD")] = {
					date: startOfWeek.add(i, "day").format("YYYY-MM-DD"),
					status: "target meet",
				};
				daysWithWorkout += 1;
				targetMeetDays += 1;
				continue;
			}
			compliance[startOfWeek.add(i, "day").format("YYYY-MM-DD")] = {
				date: startOfWeek.add(i, "day").format("YYYY-MM-DD"),
				status: "skipped",
			};
			daysWithWorkout += 1;
			continue;
		}
		const complianceArray = Object.values(compliance);
		return { complianceArray, targetMeetDays, daysWithWorkout };
	}, [props]);

	const deletePromise = useCallback(
		() =>
			supabase.from("coach_athletes").delete().eq("athlete_id", props.user_id),
		[supabase],
	);

	const deleteCall = useAsyncState<SupabaseCall<typeof deletePromise>>();

	const sendDisconnectionNotificationPromise = useCallback(async () => {
		return supabase.from("notifications").insert({
			type: "Disconnected",
			content: {
				description: `Coach ${user.user_metadata.name} (${user.email}) has disconnected.`,
			},
			user_id: props.user_id,
		});
	}, [supabase, user]);

	const sendDisconnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof sendDisconnectionNotificationPromise>>();

	useEffect(() => {
		if (isFulfilled(sendDisconnectionNotificationCall)) {
			if (sendDisconnectionNotificationCall.result) {
				enqueueSnackbar("Disconnected!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "success",
					autoHideDuration: 2000,
				});
			} else {
				enqueueSnackbar("Disconnection failed!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	}, [sendDisconnectionNotificationCall]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					padding: 2,
					background: "#2C262D",
					borderRadius: 5,
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
						<img
							src={avatar}
							alt="avatar"
							style={{
								borderRadius: "50%",
								width: "50px",
								height: "50px",
								objectFit: "cover",
							}}
						/>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography variant="h4">{props.name}</Typography>
							<Chip
								label={props.athlete_experience_level?.experience_level}
								size="small"
								sx={{
									backgroundColor:
										props.athlete_experience_level?.experience_level ===
										"Beginner"
											? "#F89F5B"
											: props.athlete_experience_level?.experience_level ===
													"Intermediate"
												? "#E53F71"
												: props.athlete_experience_level?.experience_level ===
														"Advanced"
													? "#9C3587"
													: "purple",
								}}
							/>
						</Box>
					</Box>
					<IconButton
						onClick={(event) => {
							event.preventDefault();
							setDeleteDialog(true);
						}}
						sx={{
							alignSelf: "flex-start",
						}}
					>
						<img src={close} alt="close" />
					</IconButton>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Typography>Achievements</Typography>
					<Box sx={{ display: "flex", gap: 2 }}>
						<Box sx={{ display: "flex", gap: 0.5 }}>
							<img src={HeartIcon} alt="HeartIcon" width={15} />
							<Typography
								variant="subtitle2"
								onClick={(e) =>
									props.max_heart_rate_session_id &&
									handleNavigation(props.max_heart_rate_session_id, e)
								}
								sx={{
									opacity: !!props.max_heart_rate_session_id ? 1 : 0.5,
									textDecoration: "none",
									boxShadow: "none",
									color: "#F7F3F0",
								}}
							>
								Max. Heart Rate
							</Typography>
						</Box>
						<Box sx={{ display: "flex", gap: 0.5 }}>
							<img src={PowerIcon} alt="PowerIcon" width={15} />
							<Typography
								variant="subtitle2"
								// component={!!props.max_power_session_id ? Link : Typography}
								onClick={(e) =>
									props.max_power_session_id &&
									handleNavigation(props.max_power_session_id, e)
								}
								// to={`/coach/my_athletes/session/${props.max_power_session_id}`}
								sx={{
									opacity: !!props.max_power_session_id ? 1 : 0.5,
									textDecoration: "none",
									boxShadow: "none",
									color: "#F7F3F0",
								}}
							>
								Max. Power
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography>COMPLIANCE</Typography>
						<Typography>
							{thisWeekCompliance.daysWithWorkout === 0
								? "-"
								: (
										(thisWeekCompliance.targetMeetDays /
											thisWeekCompliance.daysWithWorkout) *
										100
									).toFixed(1)}{" "}
							%
						</Typography>
					</Box>
					<Box sx={{ display: "flex" }}>
						{thisWeekCompliance.complianceArray.map((day, index) => (
							<Box
								key={index}
								sx={{
									background: complianceStepColors[day.status],
									width: "14%",
									height: "9px",
									margin: "1px",
								}}
							/>
						))}
					</Box>
				</Box>
			</Box>

			<Dialog
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				sx={{
					"& .MuiPaper-root": {
						background: "#232a2e",
					},
				}}
				aria-modal={true}
			>
				<>
					<DialogTitle>Delete Confirmation</DialogTitle>
					<DialogContent>
						Are you sure you want to delete this Athlete?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleteDialog(false)} size="small">
							Cancel
						</Button>
						<Button
							onClick={(event) => {
								event.preventDefault();
								if ("fire" in deleteCall) {
									deleteCall.fire(async () =>
										deletePromise().then((r) => {
											console.log("DELETED");
											setDeleteDialog(false);
											return r;
										}),
									);
								}
								if ("fire" in sendDisconnectionNotificationCall) {
									sendDisconnectionNotificationCall.fire(async () =>
										sendDisconnectionNotificationPromise(),
									);
								}
							}}
							size="small"
							variant="contained"
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</>
			</Dialog>
		</>
	);
}

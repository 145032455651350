import { Box, Typography, Button, useTheme, CircularProgress, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Check from "../../../assets/img/views/settings/checkfeature.svg";
import type { Dispatch } from "react";

export type SubscriptionComponentProps = {
  sub_type: string;
  features: string[];
  features_info: string;
  pricing: string;
  pricing_info?: string;
  buttonText?: string;
  onClick?: () => void;
  isLoading?: boolean;
  canBuy: boolean;
  period?: string;
  setPeriod?: Dispatch<string>
};

export default function SubscriptionComponent(
  props: SubscriptionComponentProps,
) {
  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#342C35",
        borderRadius: "6px",
        paddingX: "3.5em",
        paddingY: "2em",
        marginTop: "1rem",
        minWidth: "360px",
        [breakpoint]: {
          minWidth: "unset",
          width: "85%",
          margin: "1rem auto 0",
        },
      }}
    >
      <Typography
        sx={{
          color: "#BC6D29",
          fontSize: "1.25rem",
          fontWeight: 700,
          marginBottom: "20px",
        }}
      >
        {props.sub_type}
      </Typography>
      {props.features.map((feature, index) => (
        <Box
          key={feature}
          sx={{ display: "flex", gap: 1, marginBottom: ".5rem" }}
        >
          <img src={Check} alt="checked-icon" />
          <Typography variant="h4">{feature}</Typography>
        </Box>
      ))}
      <Typography
        variant="body2"
        marginBottom={".6875rem"}
        marginTop={props.sub_type === "Basic" ? "1.125rem" : "2.7rem"}
        sx={{
          textDecoration: "underline",
          alignSelf: "center",
          color: "white",
        }}
        component={"a"}
        rel="noreferrer"
        target="_blank"
        href={"https://darefore.com/plans"}
      >
        {props.features_info}
      </Typography>
      <Typography
        variant="h2"
        marginBottom={"1rem"}
        sx={{ fontWeight: "400", alignSelf: "center" }}
      >
        {props.pricing}
      </Typography>
      <Typography
        variant="subtitle2"
        marginBottom={props.sub_type === "Premium" ? "1rem" : "2rem"}
        textAlign={"center"}
      >
        {props.pricing_info}
      </Typography>
      {
        props.sub_type === 'Premium' && props.canBuy && (
          <RadioGroup
            row
            onChange={(e) => props.setPeriod(e.currentTarget.value)}
            value={props.period}
            name="units"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "1rem",
              "& .MuiFormControlLabel-label": { opacity: 0.5 },
            }}
          >
            <FormControlLabel
              value="monthly"
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: "12px"}}>Monthly</span>}
            />
            <FormControlLabel
              value="yearly"
              control={<Radio color="primary" />}
              label={<span style={{ fontSize: "12px"}}>Yearly</span>}
            />
          </RadioGroup>
        )
      }
      {props.sub_type === 'Premium' &&
      <Button
        variant={props.canBuy ? "contained" : "customDisabled" }
        disabled={!!(props.isLoading || !props.canBuy  )}
        onClick={props.onClick}
        style={{
          marginTop: "auto"
        }}
      >
        { 
          props.isLoading ? (
            <CircularProgress size={20} disableShrink thickness={3} style={{color: '#BC6D29'}}/>
          ) : (
            props.buttonText
          )
        }
      </Button> }
    </Box>
  );
}

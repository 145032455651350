import {
  Box,
  Button,
  Icon,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/scss/views/RoleView.scss";

import {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { panic } from "src/utils/common";
import { match } from "ts-pattern";
import athlete from "../../assets/img/views/role/athlete-icon.svg";
import coach from "../../assets/img/views/role/coach-icon.svg";
import AthleteSetupInfo, {
  AthleteSetupInfoType,
} from "../customComponents/AthleteSetupInfo";
import PersonalSetupInfo, {
  PersonalSetupInfoType,
} from "../customComponents/PersonalSetupInfo";
import ProfileSetupLayout from "../customLayouts/ProfileSetupLayout";
import CoachSetupInfo, { CoachSetupInfoType } from "../customComponents/CoachSetupInfo";
import CoachPersonalSetupInfo, { CoachPersonalSetupInfoType } from "../customComponents/CoachPersonalSetupInfo";

export type RoleViewProps = {
  experienceLevel: Array<[number, string]>;
  trainingGoals: Array<[number, string]>;
};

export default function RoleView(props: RoleViewProps) {
  const [imageFile, setImageFile] = useState<File>();
  const [role, setRole] = useState<"athlete" | "coach" | "unselected">(
    "unselected",
  );

  const [currentPersonalSetupInfo, setCurrentPersonalSetupInfo] = useState<
    PersonalSetupInfoType | "unset"
  >();
  const [currentCoachPersonalSetupInfo, setCurrentCoachPersonalSetupInfo] = useState<
    CoachPersonalSetupInfoType | "unset"
  >();
  const [personalSetupInfo, setPersonalSetupInfo] = useState<
    PersonalSetupInfoType | "unset"
  >("unset");
  const [coachPersonalSetupInfo, setCoachPersonalSetupInfo] = useState<
    CoachPersonalSetupInfoType | "unset"
  >("unset");
  const [currentAthleteSetupInfo, setCurrentAthleteSetupInfo] = useState<
    AthleteSetupInfoType | "unset"
  >("unset");
  const [currentCoachSetupInfo, setCurrentCoachSetupInfo] = useState<
  CoachSetupInfoType | "unset"
  >("unset");

  const reducer: Reducer<
    "roleSelection" | "Step 1" | "Step 2",
    "back" | "next"
  > = useCallback(
    (prev, action) =>
      match([prev, action] as const)
        .with(["roleSelection", "next"], () => "Step 1" as const)
        .with(["Step 1", "back"], () => "roleSelection" as const)
        .with(["Step 1", "next"], () => "Step 2" as const)
        .with(["Step 2", "back"], () => "Step 1" as const)
        .otherwise((_) => prev),
    [],
  );

  const [currentStep, dispatch] = useReducer(reducer, "roleSelection");
  const isMobileScreen = useMediaQuery("(max-width:450px)");

  return (
    <>
      {currentStep === "roleSelection" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingTop: "6.375rem",
            "@media (max-width: 505px)": {
              alignItems: isMobileScreen ? "center" : "flex-start",
            },
          }}
        >
          <Box
            sx={{
              background: "#2A252B",
              width: isMobileScreen ? "20rem" : "47.1875rem",
              borderRadius: "1.25rem",
              paddingTop: isMobileScreen ? "2rem" : "5rem",
              paddingBottom: "3rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1.5rem"
            }}
          >
            <Typography variant="h1" id="roleview_title">
              Choose Your Role
            </Typography>

            <Box className="role-box">
              <Stack direction={"row"} mb={".5rem"}>
                <Icon sx={{ mt: "1.25rem" }}>
                  <img src={athlete} className="svgfix" />
                </Icon>
                <Typography variant="h2" sx={{ px: ".5rem", mt: "1rem" }}>
                  Athlete
                </Typography>
              </Stack>

              <Typography variant="h4" mb={"1.625rem"}>
                Join as an athlete and receive personalized coaching, track your
                progress, and connect with other cyclists.
              </Typography>
              <Button
                variant="contained"
                size="medium"
                className="button_role-box"
                onClick={(_) => (setRole("athlete"), dispatch("next"))}
              >
                Join as an Athlete
              </Button>
            </Box>
            <Box className="role-box">
              <Stack direction={"row"} pb={".5rem"}>
                <Icon sx={{ mt: "1.25rem" }}>
                  <img src={coach} className="svgfix" />
                </Icon>
                <Typography variant="h2" sx={{ px: ".5rem", mt: "1rem" }}>
                  Coach
                </Typography>
              </Stack>

              <Typography variant="h4" mb={"1.625rem"}>
                Join as a coach and connect with athletes, create customized
                training plans, and grow your coaching business.
              </Typography>
              <Button
                variant="coachVariant"
                size="medium"
                className="button_role-box"
                onClick={(_) => (setRole("coach"), dispatch("next"))}
              >
                Join as a Coach
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
          role === "athlete" ? (
            <ProfileSetupLayout counter={currentStep === "Step 1" ? 1 : 2}>
            {currentStep === "Step 1" ? (
              <PersonalSetupInfo
                onSubmit={(values) => (
                  setPersonalSetupInfo(values), dispatch("next")
                )}
                onChange={(values) => setCurrentPersonalSetupInfo(values)}
                onBack={() => dispatch("back")}
                setImageFile={setImageFile}
                imageFile={imageFile}
                initialValues={
                  currentPersonalSetupInfo === "unset"
                    ? null
                    : currentPersonalSetupInfo
                }
              />
            ) : (
              <AthleteSetupInfo
                personalSetupInfo={
                  personalSetupInfo == "unset"
                    ? panic("Never happens")
                    : personalSetupInfo
                }
                imageFile={imageFile}
                onBack={() => dispatch("back")}
                experienceLevel={props.experienceLevel}
                trainingGoals={props.trainingGoals}
                onChange={setCurrentAthleteSetupInfo}
                initialValues={
                  currentAthleteSetupInfo === "unset"
                    ? null
                    : currentAthleteSetupInfo
                }
              />
            )}
          </ProfileSetupLayout>
          ) : (
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          paddingTop: "102px",
          "@media (max-width: 640px)": {
            alignItems: isMobileScreen ? "center" : "flex-start",
          },
        }}
      >
        <Box
          sx={{
            background: "#2A252B",
            width: isMobileScreen ? "22rem" : "47.1875rem",
            paddingBottom: "1.875rem",
            borderRadius: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              mr: "1rem",
              mt: "1rem",
            }}
          >
            <Typography
              variant="body2"
              lineHeight="14px"
              sx={{color: "#DD4F4A"}}
            >
              {currentStep === "Step 1" ? 1 : 2}/2
            </Typography>
          </Box>
            {currentStep === "Step 1" ? (
                <CoachPersonalSetupInfo
                  onSubmit={(values) => (
                    setCoachPersonalSetupInfo(values), dispatch("next")
                  )}
                  onChange={(values) => setCurrentCoachPersonalSetupInfo(values)}
                  onBack={() => dispatch("back")}
                  initialValues={
                    currentCoachPersonalSetupInfo === "unset"
                      ? null
                      : currentCoachPersonalSetupInfo
                  }
                />
              ) : (
                <CoachSetupInfo
                  coachPersonalSetupInfo={
                    coachPersonalSetupInfo == "unset"
                      ? panic("Never happens")
                      : coachPersonalSetupInfo
                  }
                  onBack={() => dispatch("back")}
                  onChange={setCurrentCoachSetupInfo}
                  initialValues={
                    currentCoachSetupInfo === "unset"
                      ? null
                      : currentCoachSetupInfo
                  }
                />
              )}
              </Box>
            </Box>  
          )
      )}
    </>
  );
}

import { Box, Divider, Typography } from "@mui/material";
import type { Database } from "src/utils/DatabaseDefinitions";
import dayjs from "dayjs";
import { useMemo } from "react";
import weekOfYear from "dayjs/plugin/weekOfYear"; // import plugin
import isoWeek from "dayjs/plugin/isoWeek";
import type { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
import Up from "../../assets/img/views/dashboard-widgets/TrendUp.svg";
import Down from "../../assets/img/views/dashboard-widgets/TrendDown.svg";
import Stale from "../../assets/img/views/dashboard-widgets/NoChange.svg";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type TrendsGraphProps = {
  sessions: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    | "position_score"
    | "efficiency_factor"
    | "effort_score"
    | "normalized_power"
    | "intensity_factor"
    | "speed_efficiency"
    | "tss"
    | "variability_index"
    | "workload"
    | "date"
  >[];
  activeTitle: string;
  activeDescription: string;
  weeksAverage: {
    position_score: number;
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    speed_efficiency: number;
    tss: number;
    variability_index: number;
    workload: number;
  };
  yearAverage: {
    position_score: number;
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    speed_efficiency: number;
    tss: number;
    variability_index: number;
    workload: number;
  };
  rangeHigh: dayjs.Dayjs;
  rangeLow: dayjs.Dayjs;
  newRangeHigh: dayjs.Dayjs;
  lastWeek: Day[];
};

export default function TrendsGraph(props: TrendsGraphProps) {
  const data = useMemo(() => {
    return props.activeTitle === "Ideal Positions"
      ? "position_score"
      : props.activeTitle === "Efficiency Factor"
      ? "efficiency_factor"
      : props.activeTitle === "Effort Score"
      ? "effort_score"
      : props.activeTitle === "Speed efficiency"
      ? "speed_efficiency"
      : props.activeTitle === "Normalised Power®"
      ? "normalized_power"
      : props.activeTitle === "TSS®"
      ? "tss"
      : props.activeTitle === "Intensity Factor®"
      ? "intensity_factor"
      : props.activeTitle === "Variability Index"
      ? "variability_index"
      : props.activeTitle === "Workload"
      ? "workload"
      : "";
  }, [props.activeTitle]);

  const categories = useMemo(() => {
    const indexOfMonth =
      props.rangeLow.month() === 11
        ? props.rangeLow.month()
        : props.rangeLow.add(1, "month").month();
    return props.rangeLow.month() === 11
      ? [months[indexOfMonth], ...months.slice(0)]
      : [
          months[indexOfMonth - 1],
          ...months.slice(indexOfMonth),
          ...months.slice(0, indexOfMonth),
        ];
  }, [props.rangeLow]);
  // finding how many days is the first week
  const firstWeek = useMemo(() => {
    const indexOfDay = props.rangeLow.isoWeekday();
    const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return [...days.slice(indexOfDay - 1)];
  }, [props.rangeLow]);

  // Sessions for the first week
  const firstWeekSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        props.rangeLow.isSameOrBefore(new Date(session.date), "day") &&
        props.rangeLow
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(session.date), "day"),
    );
  }, [props.rangeLow]);

  // new range low removed the first week.
  const newRangeLow = useMemo(() => {
    return props.rangeLow.add(firstWeek.length, "day");
  }, [props.rangeLow]);

  // last week sessions
  const lastWeekSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        props.rangeHigh
          .subtract(props.lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(session.date), "day") &&
        props.rangeHigh.isSameOrAfter(new Date(session.date), "day"),
    );
  }, [props.rangeLow]);

  // new range high removed last week
  const newRangeHigh = useMemo(() => {
    return props.rangeHigh.subtract(props.lastWeek.length, "day");
  }, [props.rangeHigh]);

  // sessions between new low and new high
  const filterSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        newRangeLow.isSameOrBefore(new Date(session.date), "day") &&
        newRangeHigh.isSameOrAfter(new Date(session.date), "day"),
    );
  }, [newRangeLow, newRangeHigh]);

  // Averages per week for the 52 weeks
  const resultObject = useMemo(() => {
    const sumObject = {};
    const countObject = {};
    const result = {};
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      sumObject[i] = 0; // Initialize with default value
      countObject[i] = 0; // Initialize with default value
      // Filtering Sessions for Each Week
      const weekSession = filterSessions.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.date), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.date), "day"),
      );

      if (weekSession.length > 0) {
        for (const session of weekSession) {
          if (session[data] !== 0 && session[data] !== null) {
            sumObject[i] += session[data];
            countObject[i] += 1;
          }
        }
      }
    }
    sumObject[0] = 0;
    countObject[0] = 0;
    if (firstWeekSessions.length > 0) {
      for (const session of firstWeekSessions) {
        if (session[data] !== 0 && session[data] !== null) {
          sumObject[0] += session[data];
          countObject[0] += 1;
        }
      }
    }
    sumObject[52] = 0;
    countObject[52] = 0;
    if (lastWeekSessions.length > 0) {
      for (const session of lastWeekSessions) {
        if (session[data] !== 0 && session[data] !== null) {
          sumObject[52] += session[data];
          countObject[52] += 1;
        }
      }
    }
    for (const key in sumObject) {
      if (countObject[key] > 0) {
        result[key] = sumObject[key] / countObject[key];
      } else {
        result[key] = 0;
      }
    }
    return result;
  }, [data]);

  const customGroups = useMemo(() => {
    const groupsObject = categories.map((item) => {
      return { title: item?.toString(), cols: 0 };
    });
    groupsObject[0].cols = 1;
    groupsObject[12].cols = 1;
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      const updatedRangeLow = newRangeLow.add((i - 1) * 7, "day");
      const updateMonth = months[updatedRangeLow.month()];
      if (updateMonth === categories[0]) {
        if (updatedRangeLow.year() < newRangeHigh.year()) {
          groupsObject[0].cols = groupsObject[0].cols + 1;
        } else {
          groupsObject[12].cols = groupsObject[12].cols + 1;
        }
      } else {
        const objIndex = groupsObject.findIndex(
          (obj) => obj.title === updateMonth,
        );
        groupsObject[objIndex].cols = groupsObject[objIndex].cols + 1;
      }
    }
    return groupsObject;
  }, []);

  const chartOptions: ApexOptions = {
    chart: {
      id: "Trends",
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: { show: true, rotate: 360 },
      categories: Object.keys(resultObject),
      overwriteCategories: [],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      group: {
        groups: customGroups,
        style: {
          colors: "#BC6D29",
          fontWeight: 700,
          fontSize: "16px",
        },
      },
      offsetY: -30,
    },

    yaxis: {
      labels: { show: false },
    },
    grid: {
      show: false,
      padding: {
        top: 10,
        right: 20,
        bottom: 100,
        left: 35,
      },
    },
    annotations: {
      yaxis: [
        {
          y:
            props.activeTitle === "Ideal Positions"
              ? props.yearAverage[data] * 100
              : props.yearAverage[data],
          borderColor: "#BFBBB8",
          label: {
            borderColor: "#BFBBB8",
            position: "left",
            offsetX: 30,
            style: {
              color: "#201B20",
              background: "#BFBBB8",
            },
            text: `${
              props.activeTitle === "Ideal Positions"
                ? Number(props.yearAverage[data] * 100).toFixed(1)
                : props.activeTitle === "Normalised Power®"
                ? Number(props.yearAverage[data]).toFixed(0)
                : props.activeTitle === "TSS®" ||
                  props.activeTitle === "Workload"
                ? Number(props.yearAverage[data]).toFixed(1)
                : Number(props.yearAverage[data]).toFixed(2)
            }${
              props.activeTitle === "Normalised Power®"
                ? " W"
                : props.activeTitle === "Ideal Positions"
                ? " %"
                : ""
            }`,
          },
        },
        {
          y:
            props.activeTitle === "Ideal Positions"
              ? props.weeksAverage[data] * 100
              : props.weeksAverage[data],
          borderColor: "#BC6D29",
          label: {
            borderColor: "#BC6D29",
            position: "right",
            style: {
              color: "#BC6D29",
              background: "#201B20",
            },
          
            text: `${
              props.activeTitle === "Ideal Positions"
                ? Number(props.weeksAverage[data] * 100).toFixed(1)
                : props.activeTitle === "Normalised Power®"
                ? Number(props.weeksAverage[data]).toFixed(0)
                : props.activeTitle === "TSS®" ||
                  props.activeTitle === "Workload"
                ? Number(props.weeksAverage[data]).toFixed(1)
                : Number(props.weeksAverage[data]).toFixed(2)
            }${
              props.activeTitle === "Normalised Power®"
                ? " W"
                : props.activeTitle === "Ideal Positions"
                ? " %"
                : ""
            }`,
          },
        
        },

      ],
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "40%",
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return dataPointIndex === 0
            ? `<div style="text-align: center; font-size:16px; color: #fff; padding: 5px; border-radius: 5px;">${props.activeTitle === "Normalised Power®" ?  series[0][dataPointIndex].toFixed(0) : (props.activeTitle === "Ideal Positions" || props.activeTitle === 'TSS®' || props.activeTitle === 'Workload') ?  series[0][dataPointIndex].toFixed(1): series[0][dataPointIndex].toFixed(2)}${
              props.activeTitle === "Normalised Power®" ? " W" : props.activeTitle === "Ideal Positions" ? " %" : "" 
            }</div> <div style="text-align: center; font-size:14px; color: #767577; padding: 5px; border-radius: 5px;"> ${props.rangeLow.format("DD MMM")} - ${props.rangeLow.add(firstWeek.length-1, "day").format("DD MMM")} </div> ` //prettier-ignore
            : dataPointIndex === 52
            ? `<div style="text-align: center; font-size:16px; color: #fff; padding: 5px; border-radius: 5px;">${props.activeTitle === "Normalised Power®" ?  series[0][dataPointIndex].toFixed(0) : (props.activeTitle === "Ideal Positions" || props.activeTitle === 'TSS®' || props.activeTitle === 'Workload') ?  series[0][dataPointIndex].toFixed(1): series[0][dataPointIndex].toFixed(2)}${
              props.activeTitle === "Normalised Power®" ? " W" : (props.activeTitle === "Ideal Positions" || props.activeTitle === 'TSS®' || props.activeTitle === 'Workload') ? " %" : ""
            }</div> <div style="text-align: center; font-size:14px; color: #767577; padding: 5px; border-radius: 5px;">${props.rangeHigh.subtract(props.lastWeek.length-1, "day").format("DD MMM")} - ${props.rangeHigh.format("DD MMM")} </div>` //prettier-ignore
            : `<div style="text-align: center; font-size:16px; color: #fff; padding: 5px; border-radius: 5px;">${props.activeTitle === "Normalised Power®" ?  series[0][dataPointIndex].toFixed(0) : (props.activeTitle === "Ideal Positions" || props.activeTitle === 'TSS®' || props.activeTitle === 'Workload') ?  series[0][dataPointIndex].toFixed(1): series[0][dataPointIndex].toFixed(2)}${
              props.activeTitle === "Normalised Power®" ? " W" : props.activeTitle === "Ideal Positions" ? " %" : ""
            }</div>  <div style="text-align: center; font-size:14px; color: #767577; padding: 5px; border-radius: 5px;"> ${newRangeLow.add(dataPointIndex-1, "week").format("DD MMM")} - ${newRangeLow.add(dataPointIndex-1,"week").add(6,'day').format("DD MMM")} </div>` //prettier-ignore
      },
    },
    colors: ["#A19D9A"],
  };

  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    return [
      {
        name: "TrendsGraph",
        data: Object.keys(resultObject).map((key, index) => ({
          x: key,
          y:
            props.activeTitle === "Ideal Positions"
              ? resultObject[key] * 100
              : resultObject[key],
          fillColor: index >= 40 && index <= 52 ? "#BC6D29" : "#A19D9A",
        })),
      },
    ];
  }, [resultObject]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#2C262D",
          padding: "0.5em",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: { md: "530px", xl: "650px" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ marginBottom: "10px" }}>
              {props.activeTitle}
            </Typography>
            <Typography>{props.activeDescription}</Typography>
          </Box>
          <Box display={"flex"} flex={1} justifyContent={"flex-end"} mb={"5px"}>
            <img
              src={
                Number(props.weeksAverage[data]).toFixed(2) >
                Number(props.yearAverage[data]).toFixed(2)
                  ? Up
                  : Number(props.weeksAverage[data]).toFixed(2) <
                    Number(props.yearAverage[data]).toFixed(2)
                  ? Down
                  : Stale
              }
              alt="trendup-icon"
              width={"40px"}
            />
          </Box>
        </Box>

        <Divider sx={{ marginY: "8px" }} />
        <Typography fontSize={".6875rem"}>
          {props.rangeLow.format("DD MMMM YYYY")} -{" "}
          {props.rangeHigh.format("DD MMMM YYYY")}
        </Typography>

        <Chart
          options={chartOptions as ApexOptions}
          series={chartSeries}
          type="bar"
          height={"100%"}
        />
      </Box>
    </>
  );
}

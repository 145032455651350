import { Box, Button, Chip, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import { useSnackbar } from "notistack";
import { isFulfilled } from "@reduxjs/toolkit";
import useAsyncState from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";

export type AthleteComponentProps = {
	user_id: string;
	name: string;
	image: string;
	athlete_experience_level: {
		experience_level: string;
	};
};

export default function CoachAthleteOutgoing(props: AthleteComponentProps) {
	const supabase = useSupabase();
	const user = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const avatar = useMemo(() => {
		if (props.image) {
			return supabase.storage.from("avatars").getPublicUrl(props.image, {
				transform: {
					width: 50,
					height: 50,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 50,
			radius: 55,
			seed: props.name,
		}).toDataUriSync();
	}, [props]);

	//Delete
	const rejectRequestPromise = useCallback(
		() =>
			supabase
				.from("coach_athletes")
				.delete()
				.eq("athlete_id", props.user_id)
				.eq("coach_id", user.id)
				.throwOnError()
				.then(() => {
					if ("fire" in rejectConnectionNotificationCall) {
						rejectConnectionNotificationCall.fire(async () =>
							rejectConnectionNotificationPromise(props.user_id),
						);
					}
				}),
		[supabase],
	);

	const rejectRequestCall =
		useAsyncState<SupabaseCall<typeof rejectRequestPromise>>();

	const rejectConnectionNotificationPromise = useCallback(
		async (userId: string) => {
			return supabase
				.from("notifications")
				.insert({
					type: "Connection Canceled",
					content: {
						description: `Coach ${user.user_metadata.name} (${user.email}) has canceled their connection request.`,
					},
					user_id: userId,
				})
				.then((_) => {
					enqueueSnackbar("Connection request canceled!", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "top",
						},
						variant: "success",
						autoHideDuration: 2000,
					});
				});
		},
		[supabase, user],
	);

	const rejectConnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof rejectConnectionNotificationPromise>>();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					gap: 3,
					padding: 2,
					background: "#2C262D",
					borderRadius: 5,
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
					<img
						src={avatar}
						alt="avatar"
						style={{
							borderRadius: "50%",
							width: "50px",
							height: "50px",
							objectFit: "cover",
						}}
					/>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
						<Typography variant="h4">{props.name}</Typography>
						<Chip
							label={props.athlete_experience_level?.experience_level}
							size="small"
							sx={{
								backgroundColor:
									props.athlete_experience_level?.experience_level ===
									"Beginner"
										? "#F89F5B"
										: props.athlete_experience_level?.experience_level ===
												"Intermediate"
											? "#E53F71"
											: props.athlete_experience_level?.experience_level ===
													"Advanced"
												? "#9C3587"
												: "purple",
							}}
						/>
					</Box>
				</Box>

				<Button
					onClick={() => {
						if ("fire" in rejectRequestCall) {
							rejectRequestCall.fire(async () =>
								rejectRequestPromise().then((r) => {
									return r;
								}),
							);
						}
					}}
				>
					Cancel Request
				</Button>
			</Box>
		</>
	);
}

import { Box, Grid, Tab } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, {
	isFulfilled,
	isPending,
	isUnloaded,
} from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import CoachAthleteIncoming from "../Requests/CoachAthleteIncoming";
import CoachAthleteOutgoing from "../Requests/CoachAthleteOutgoing";
import SuspenseLoader from "src/components/SuspenseLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";

type TabValue = "received" | "sent";

export type RequestsProps = {
	tab?: TabValue;
};

export default function CoachRequestsView(props: RequestsProps) {
	const supabase = useSupabase();
	const user = useUser();
	const [, setTitle] = useTitleCoach();
	const [value, setValue] = useState<TabValue>("received");

	useEffect(() => {
		if (props?.tab) {
			setValue(props?.tab);
		}
	}, [props]);

	useEffect(() => {
		setTitle("Requests");
	}, []);

	const handleChange = useCallback((_, newValue: TabValue) => {
		setValue(newValue);
		window.history.replaceState(null, "", `/coach/requests/${newValue}`);
	}, []);

	const coachIncomingPromise = useCallback(
		() =>
			supabase
				.from("coach_athletes")
				.select(
					"*,athlete(user_id,name,image,athlete_experience_level(experience_level))",
				)
				.eq("coach_id", user.id)
				.eq("from_user", true)
				.eq("status", "Pending")
				.then((res) => res.data),
		[supabase],
	);

	const coachIncomingCall =
		useAsyncState<SupabaseCall<typeof coachIncomingPromise>>();

	useEffect(() => {
		if (isUnloaded(coachIncomingCall)) {
			coachIncomingCall.fire(async () => coachIncomingPromise());
		}
	}, [coachIncomingPromise, coachIncomingCall]);

	const coachOutgoingPromise = useCallback(
		() =>
			supabase
				.from("coach_athletes")
				.select(
					"*,athlete(user_id,name,image,athlete_experience_level(experience_level))",
				)
				.eq("coach_id", user.id)
				.eq("from_user", false)
				.eq("status", "Pending")
				.then((res) => res.data),
		[supabase],
	);

	const coachOutgoingCall =
		useAsyncState<SupabaseCall<typeof coachOutgoingPromise>>();

	useEffect(() => {
		if (isUnloaded(coachOutgoingCall)) {
			coachOutgoingCall.fire(async () => coachOutgoingPromise());
		}
	}, [coachOutgoingPromise, coachOutgoingCall]);

	if (isPending(coachIncomingCall) || isPending(coachOutgoingCall)) {
		return <SuspenseLoader color="#DD4F4A" />;
	}

	return (
		<Box
			sx={{
				width: "100%",
				paddingTop: "10px",
				paddingLeft: "25px",
				paddingBottom: "20px",
			}}
		>
			<Box>
				<TabContext value={value}>
					<Box
						sx={{
							borderColor: "divider",
							display: "flex",
							paddingRight: "20px",
							justifyContent: "space-between",
						}}
					>
						<TabList
							onChange={handleChange}
							variant="scrollable"
							aria-label="Tab list"
							sx={{
								"& .MuiTab-root:not(.Mui-selected)": {
									opacity: 0.5,
								},
								minHeight: "1.5rem",
								"& .Mui-selected": {
									color: "#DD4F4A !important",
								},
							}}
							TabIndicatorProps={{
								sx: {
									backgroundColor: "#DD4F4A",
								},
							}}
						>
							<Tab
								label="Received"
								value="received"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
							<Tab
								label="Sent"
								value="sent"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
						</TabList>
					</Box>
					<TabPanel
						value="received"
						sx={{
							padding: 0,
							minHeight: 0,
							marginTop: "1rem",
							"& .customGridStyle": {
								color: "#DD4F4A",
								borderColor: "#DD4F4A",
							},
						}}
					>
						{isFulfilled(coachIncomingCall) && (
							<Grid container gap={1} mt={"1rem"}>
								{coachIncomingCall.result.map((user) => (
									<Grid
										item
										xl={1.5}
										lg={2.7}
										md={5}
										xs={8}
										key={user.id}
										sx={{ color: "#F7F3F0", textDecoration: "none" }}
									>
										<CoachAthleteIncoming {...user.athlete} />
									</Grid>
								))}
							</Grid>
						)}
					</TabPanel>
					<TabPanel
						value="sent"
						sx={{
							padding: 0,
							minHeight: 0,
							marginTop: "1rem",
							"& .customGridStyle": {
								color: "#DD4F4A",
								borderColor: "#DD4F4A",
							},
						}}
					>
						{isFulfilled(coachOutgoingCall) && (
							<Grid container gap={1} mt={"1rem"}>
								{coachOutgoingCall.result.map((user) => (
									<Grid
										item
										xl={1.5}
										lg={2.7}
										md={5}
										xs={8}
										key={user.id}
										sx={{ color: "#F7F3F0", textDecoration: "none" }}
									>
										<CoachAthleteOutgoing {...user.athlete} />
									</Grid>
								))}
							</Grid>
						)}
					</TabPanel>
				</TabContext>
			</Box>
		</Box>
	);
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import PositionsProgress from "../customComponents/PositionsProgress";
import { useTitle } from "../customLayouts/DashboardLayout";
import PerformanceProgress from "../customComponents/PerformanceProgress";
import { useNavigate } from "react-router-dom";
import LockScreen from "../customComponents/LockScreen";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import type { SupabaseCall } from "src/utils/common";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import PremiumLogo from "../../assets/img/views/lock/logoLock.svg";

type TabValue = "performance" | "positions";

export type ProgressViewProps = {
	tab?: TabValue;
};

export default function ProgressView(props: ProgressViewProps) {
	const [, setTitle] = useTitle();
	const supabase = useSupabase();
	const user = useUser();

	const subscriptionPromise = useCallback(
		() =>
			supabase
				.from("athlete_subscription")
				.select("*")
				.eq("athlete_id", user.id)
				.then((res) => res.data[0]),
		[supabase],
	);

	const subscriptionCall =
		useAsyncState<SupabaseCall<typeof subscriptionPromise>>();

	// Loading effect
	useEffect(() => {
		if (isUnloaded(subscriptionCall)) {
			subscriptionCall.fire(async () => subscriptionPromise());
		}
	}, [subscriptionCall, subscriptionPromise]);
	const isPremium = useMemo(() => {
		if (isFulfilled(subscriptionCall)) {
			return subscriptionCall?.result?.status === "active";
		}
	}, [subscriptionCall]);

	useEffect(() => {
		setTitle("Progress");
	}, []);

	const [value, setValue] = useState<TabValue>("performance");

	const navigate = useNavigate();

	const handleChange = useCallback((_, newValue: TabValue) => {
		navigate(`/dashboard/progress/${newValue}`);
	}, []);

	useEffect(() => {
		setValue(props.tab);
	}, [props?.tab]);

	return (
		<>
			<Box
				sx={{
					width: "100%",
					paddingTop: "15px",
					paddingLeft: "25px",
				}}
			>
				<TabContext value={value}>
					<Box
						sx={{
							borderColor: "divider",
						}}
					>
						<TabList
							onChange={handleChange}
							aria-label="Tab list"
							sx={{
								"& .MuiTab-root:not(.Mui-selected)": {
									opacity: 0.5,
								},
							}}
						>
							<Tab label="Performance" value="performance" />
							<Tab
								label="Positions"
								value="positions"
								icon={
									<img
										src={PremiumLogo}
										alt="lock-logo"
										width={"20px"}
										height={"20px"}
									/>
								}
								iconPosition="end"
								sx={{
									"& .MuiTab-iconWrapper": { marginLeft: "1px" },
								}}
							/>
						</TabList>
					</Box>
					<TabPanel value="performance" sx={{ padding: 0 }}>
						<PerformanceProgress />
					</TabPanel>
					<TabPanel value="positions" sx={{ padding: 0 }}>
						{isPremium ? (
							<PositionsProgress />
						) : (
							<LockScreen
								width="98%"
								description="Gain insights into how much time you spend in each cycling position every month, whether you are riding Indoor or Outdoor, on Road or TT bikes./n
Visualize your position data to make informed adjustments that can enhance your efficiency and comfort across different terrains and bike setups."
								useFormattedDescription={true}
							/>
						)}
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
}

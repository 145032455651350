import Box from "@mui/material/Box";
import {
	DataGrid,
	type GridColDef,
	type GridFilterModel,
	GridToolbar,
	type GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { timeFormatter, type SupabaseCall } from "src/utils/common";
import { useUser } from "src/components/Authenticated";
import {
	Button,
	IconButton,
	Modal,
	Paper,
	Tab,
	Typography,
} from "@mui/material";
import ActionsOutlined from "../../assets/img/views/dashboard-sessions/ActionsOutlined.svg";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AdminDashboardUserDetails from "./AdminDashboardUserDetails";
import AdminDashboardAthleteDetails from "./AdminDashboardAthleteDetails";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import AdminDashboardBasicSubscription from "./AdminDashboardBasicSubscription";
import AdminDashboardPremiumSubscription from "./AdminDashboardPremiumSubscription";

export default function UserDataGrid() {
	const supabase = useSupabase();
	const [rows, setRows] = useState([]);
	const [rowSession, setRowsession] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModal] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string>(null);
	const [value, setValue] = useState("1");
	const [innerValue, setInnerValue] = useState("1");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const handleInnerChange = (event: React.SyntheticEvent, newValue: string) => {
		setInnerValue(newValue);
	};

	const [filterIndex, setFilterIndex] = useState(0);
	const [filterModel, setFilterModel] = useState<GridFilterModel>({
		items: [
			{
				columnField: "provider",
				operatorValue: "contains",
				value: "Darefore",
			},
		],
	});

	useEffect(() => {
		setFilterModel({
			items: [
				filterIndex === 0
					? {
							columnField: "provider",
							operatorValue: "contains",
							value: "Darefore",
						}
					: {
							columnField: "provider",
							operatorValue: "contains",
							value: "Garmin",
						},
			],
		});
	}, [filterIndex]);

	const [previousFilterModel, setPreviousFilterModel] = useState(null);

	const handleCloseModal = () => {
		setIsModalOpen(false);
		// Restore the previous filter state when exiting the modal
		if (previousFilterModel !== null) {
			setFilterModel(previousFilterModel);
		}
	};

	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Full Name",
			editable: false,
			width: 200,
		},
		{
			field: "email",
			headerName: "Email",
			editable: false,
			width: 200,
			getApplyQuickFilterFn: undefined,
		},

		{
			field: "last_login",
			headerName: "Last Login",
			editable: false,
			getApplyQuickFilterFn: undefined,
			width: 200,
			type: "dateTime",
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			valueFormatter: (params: GridValueFormatterParams<any>) =>
				dayjs(params.value).format("DD/MM/YYYY HH:mm:ss"),
		},
		{
			field: "weekly_logins",
			headerName: "Weekly Logins",
			editable: false,
			width: 200,
			getApplyQuickFilterFn: undefined,
		},
		{
			field: "level",
			headerName: "Level",
			editable: false,
			width: 180,
			getApplyQuickFilterFn: undefined,
		},
		{
			field: "country",
			headerName: "Country",
			editable: false,
			width: 100,
			getApplyQuickFilterFn: undefined,
		},
		{
			field: "sessions",
			headerName: "Sessions",
			editable: false,
			disableColumnMenu: true,
			sortable: false,

			renderCell: (field) => (
				<Box>
					<IconButton
						onClick={() => {
							setSelectedUserId(field.row.id);
							setIsModalOpen(true);
							setPreviousFilterModel({ ...filterModel });
							setFilterModel({ items: [] });
						}}
					>
						<img src={ActionsOutlined} alt="actions-icon" width={"15px"} />
					</IconButton>
				</Box>
			),
		},
		{
			field: "df_sessions",
			headerName: "DF Sessions",
			editable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (field) => (
				<Box>
					<IconButton
						onClick={() => {
							setSelectedUserId(field.row.id);
							setIsModalOpen(true);
							setFilterIndex(0);
						}}
					>
						<img src={ActionsOutlined} alt="actions-icon" width={"15px"} />
					</IconButton>
				</Box>
			),
		},
		{
			field: "garmin_sessions",
			headerName: "GM Sessions",
			editable: false,
			disableColumnMenu: true,
			sortable: false,
			width: 120,
			renderCell: (field) => (
				<Box>
					<IconButton
						onClick={() => {
							setSelectedUserId(field.row.id);
							setIsModalOpen(true);
							setFilterIndex(1);
						}}
					>
						<img src={ActionsOutlined} alt="actions-icon" width={"15px"} />
					</IconButton>
				</Box>
			),
		},
		{
			field: "user",
			headerName: "User Details",
			editable: false,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (field) => (
				<Box>
					<IconButton
						onClick={() => {
							setIsUserModalOpen(true);
							setSelectedUserId(field.row.id);
						}}
					>
						<ContactPageIcon />
					</IconButton>
				</Box>
			),
		},

		{
			field: "premium",
			headerName: "Subscription",
			editable: false,
			width: 200,
			disableColumnMenu: true,
			getApplyQuickFilterFn: undefined,
			renderCell: (field) => (
				<Box sx={{ flexDirection: "row", display: "flex" }}>
					<Typography sx={{ alignSelf: "center" }}>
						{field.row.premium}
					</Typography>
					<IconButton
						onClick={() => {
							setIsSubscriptionModal(true);
							setSelectedUserId(field.row.id);
						}}
					>
						<EditIcon />
					</IconButton>
				</Box>
			),
		},
	];

	const experienceLevelMap = {
		1: "Beginner",
		2: "Intermediate",
		3: "Advanced",
		4: "Expert",
	};

	//Athlete Details
	const athletePromise = useCallback(
		() =>
			supabase
				.from("athlete")
				.select("*")
				.then((res) => res.data),
		[supabase],
	);

	const storedAthleteCall =
		useAsyncState<SupabaseCall<typeof athletePromise>>();
	useEffect(() => {
		if (isUnloaded(storedAthleteCall)) {
			storedAthleteCall.fire(async () => athletePromise());
		}
	}, [athletePromise, storedAthleteCall]);

	//Subscription Details
	const subscriptionPromise = useCallback(
		() =>
			supabase
				.from("athlete_subscription")
				.select("*")
				.then((res) => res.data),
		[supabase],
	);

	const subscriptionCall =
		useAsyncState<SupabaseCall<typeof subscriptionPromise>>();
	useEffect(() => {
		if (isUnloaded(subscriptionCall)) {
			subscriptionCall.fire(async () => subscriptionPromise());
		}
	}, [subscriptionPromise, subscriptionCall]);

	const allUsersPromise = useCallback(
		() => supabase.functions.invoke("list-all-users"),
		[supabase],
	);

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const allUsersCall = useAsyncState<any>();

	useEffect(() => {
		if (isUnloaded(allUsersCall)) {
			allUsersCall.fire(async () => allUsersPromise());
		}
	}, [allUsersPromise, allUsersCall]);

	useEffect(() => {
		if (
			isFulfilled(storedAthleteCall) &&
			isFulfilled(allUsersCall) &&
			isFulfilled(subscriptionCall)
		) {
			const currentDate = new Date();
			const lastWeekDate = new Date(
				currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
			);
			const lastWeekAuditData = allUsersCall.result.data.auditData.filter(
				(log) => new Date(log.created_at) >= lastWeekDate,
			);
			const rows = storedAthleteCall.result.map((item) => ({
				id: item.user_id,
				name: item.name,
				email: allUsersCall.result.data.data.find(
					(user) => user.id === item.user_id,
				)?.email,
				last_login: new Date(
					allUsersCall.result.data.data.find((user) => user.id === item.user_id)
						?.last_sign_in_at,
				)?.toISOString(), //prettier-ignore
				level: experienceLevelMap[item.experience_level],
				country: item.country_code,
				weekly_logins: lastWeekAuditData.reduce((acc, log) => {
					return log.payload.actor_id === item.user_id ? acc + 1 : acc;
				}, 0),
				premium:
					subscriptionCall?.result?.find(
						(data) => data.athlete_id === item.user_id,
					)?.status === "active"
						? "Premium"
						: "Basic",
			}));
			setRows(rows);
		}
	}, [storedAthleteCall, allUsersCall, subscriptionCall]);

	//athlete_training_goals
	const athTrainingGoalsPromise = useCallback(
		() =>
			supabase
				.from("athlete_training_goals")
				.select()
				.then((res) => res.data),
		[supabase],
	);

	const storedAathTrainingGoalsCall =
		useAsyncState<SupabaseCall<typeof athTrainingGoalsPromise>>();

	useEffect(() => {
		if (isUnloaded(storedAathTrainingGoalsCall)) {
			storedAathTrainingGoalsCall.fire(async () => athTrainingGoalsPromise());
		}
	}, [storedAathTrainingGoalsCall, athTrainingGoalsPromise]);

	//Sessions Details
	const sessionsPromise = useCallback(
		() =>
			supabase
				.from("session")
				.select(
					"id,athlete_id,recording_name,date,total_distance,total_time,average_power,average_speed,type_of_ride_id,provider,average_cadence,elevation_gain,athlete_bikes(bike_types(name))",
				)
				.then((res) => res.data),
		[supabase],
	);

	const storedSessionCall =
		useAsyncState<SupabaseCall<typeof sessionsPromise>>();

	useEffect(() => {
		if (isUnloaded(storedSessionCall)) {
			storedSessionCall.fire(async () => sessionsPromise());
		}
	}, [storedSessionCall, sessionsPromise]);

	const columnsSessions: GridColDef[] = [
		{
			field: "recording_name",
			headerName: "Recording Name",
			editable: false,
			width: 200,
			sortable: true,
		},
		{
			field: "date",
			headerName: "Date",
			editable: false,
			width: 200,
			type: "dateTime",
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			valueFormatter: (params: GridValueFormatterParams<any>) =>
				dayjs(params.value).format("DD/MM/YYYY HH:mm:ss"),
		},
		{
			field: "total_distance",
			headerName: "Distance",
			editable: false,
			width: 200,
		},
		{
			field: "total_time",
			headerName: "Duration",
			editable: false,
			width: 200,
		},
		{
			field: "average_power",
			headerName: "Avg Power",
			editable: false,
			width: 200,
		},
		{
			field: "average_speed",
			headerName: "Avg Speed",
			editable: false,
			width: 200,
		},
		{
			field: "average_cadence",
			headerName: "Avg Cadence",
			editable: false,
			width: 200,
		},
		{
			field: "elevation_gain",
			headerName: "Elevation Gain",
			editable: false,
			width: 200,
		},
		{
			field: "provider",
			headerName: "Provider",
			editable: false,
			width: 200,
		},
		{
			field: "type_of_ride_id",
			headerName: "Type of Ride",
			editable: false,
			width: 200,
		},
		{
			field: "athlete_bikes",
			headerName: "Bike Type",
			editable: false,
			width: 200,
		},
	];

	const typeOfRideMap = {
		1: "Indoor",
		2: "Outdoor",
		3: "Virtual",
	};

	useEffect(() => {
		if (isFulfilled(storedSessionCall)) {
			const rowSession = storedSessionCall.result.map((item) => ({
				id: item.id,
				athlete_id: item.athlete_id,
				recording_name: item.recording_name,
				total_distance: `${item.total_distance?.toFixed(0) ?? "-"} km`,
				total_time: timeFormatter((item?.total_time / 1000).toString()),
				date: new Date(item.date)?.toISOString(),
				average_power: `${item.average_power?.toFixed(0) ?? "-"} W`,
				average_speed: `${item.average_speed?.toFixed(0) ?? "-"} km/h`,
				average_cadence: `${item.average_cadence?.toFixed(0) ?? "-"} rpm`,
				elevation_gain: `${item.elevation_gain?.toFixed(0) ?? "-"} m`,
				provider: item.provider,
				type_of_ride_id: typeOfRideMap[item.type_of_ride_id],
				athlete_bikes: item?.athlete_bikes?.bike_types?.name ?? "-",
			}));

			const userSessions = rowSession.filter(
				(session) => session.athlete_id === selectedUserId,
			);
			setRowsession(userSessions);
		}
	}, [storedSessionCall, selectedUserId]);

	// Admin Related
	const adminPromise = useCallback(
		() =>
			supabase
				.from("admin")
				.select()
				.throwOnError()
				.then((res) => res.data),
		[supabase],
	);

	const adminCall = useAsyncState<SupabaseCall<typeof adminPromise>>();

	useEffect(() => {
		if (isUnloaded(adminCall)) {
			adminCall.fire(async () => adminPromise());
		}
	}, [adminCall, adminPromise]);

	//Exp Level Fetching
	const expLevelPromise = useCallback(
		() =>
			supabase
				.from("athlete_experience_level")
				.select()
				.then((res) => res.data),
		[supabase],
	);

	const storedexpLevelCall =
		useAsyncState<SupabaseCall<typeof expLevelPromise>>();

	useEffect(() => {
		if (isUnloaded(storedexpLevelCall)) {
			storedexpLevelCall.fire(async () => expLevelPromise());
		}
	}, [storedexpLevelCall, expLevelPromise]);

	//Training Goals Fetching
	const trainingGoalPromise = useCallback(
		() =>
			supabase
				.from("training_goals")
				.select()
				.then((res) => res.data),
		[supabase],
	);

	const storedtrainingGoalCall =
		useAsyncState<SupabaseCall<typeof trainingGoalPromise>>();

	useEffect(() => {
		if (isUnloaded(storedtrainingGoalCall)) {
			storedtrainingGoalCall.fire(async () => trainingGoalPromise());
		}
	}, [storedtrainingGoalCall, trainingGoalPromise]);

	const filteredAthlete = useMemo(() => {
		if (isFulfilled(storedAthleteCall)) {
			return storedAthleteCall.result.filter(
				(item) => item.user_id === selectedUserId,
			);
		}
	}, [selectedUserId]);

	const allUsers = useMemo(() => {
		if (isFulfilled(allUsersCall) && isFulfilled(adminCall)) {
			return allUsersCall.result.data.data.filter((user) =>
				adminCall.result.every((admin) => admin.user_id !== user.id),
			);
		}
	}, [allUsersCall, adminCall]);

	const filteredAthleteEmail = useMemo(() => {
		return allUsers?.find((user) => user.id === selectedUserId)?.email;
	}, [selectedUserId]);

	const athleteTrainingsGoals = useMemo(() => {
		if (isFulfilled(storedAathTrainingGoalsCall)) {
			return storedAathTrainingGoalsCall.result.length === 0
				? []
				: storedAathTrainingGoalsCall.result.filter(
						(item) => item.athlete_id === selectedUserId,
					);
		}
	}, [selectedUserId]);

	const experienceLevel = useMemo(() => {
		if (isFulfilled(storedexpLevelCall)) return storedexpLevelCall.result;
	}, [storedexpLevelCall]);

	const trainingGoals = useMemo(() => {
		if (isFulfilled(storedtrainingGoalCall))
			return storedtrainingGoalCall.result;
	}, [storedtrainingGoalCall]);

	const athleteSubscription = useMemo(() => {
		if (isFulfilled(subscriptionCall)) {
			return subscriptionCall?.result?.length === 0
				? []
				: subscriptionCall?.result?.filter(
						(item) => item.athlete_id === selectedUserId,
					);
		}
	}, [selectedUserId]);

	return (
		<Box>
			<Box
				sx={{
					padding: "2em",
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<DataGrid
					rows={rows}
					columns={columns}
					checkboxSelection
					autoHeight
					disableDensitySelector
					disableSelectionOnClick
					initialState={{
						sorting: {
							sortModel: [{ field: "last_login", sort: "desc" }],
						},
					}}
					components={{ Toolbar: GridToolbar }}
					componentsProps={{
						columnMenu: {
							sx: {
								boxShadow: "none",
							},
						},
						filterPanel: {
							sx: {
								width: "1000px",
								padding: "2em",
								backgroundColor: "#201C20",
								placement: "center",
							},
						},
						panel: {
							placement: "bottom",
							sx: {
								top: "-120px !important",
								"& .MuiDataGrid-panelWrapper": {
									bgcolor: "#201C20",
								},
								"& .MuiInputBase-root": {
									width: "100px",
									marginTop: "0px",
									padding: "0px",
								},
								"& .MuiNativeSelect-select": {
									padding: "0px",
								},
							},
						},
						toolbar: {
							showQuickFilter: true,
							sx: {
								"& .MuiButton-startIcon": {
									display: "none",
								},
							},
						},
					}}
				/>
			</Box>
			<Modal open={isModalOpen} onClose={() => handleCloseModal()}>
				<Paper>
					<Box p={2}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h2">Sessions</Typography>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleCloseModal}
							>
								Close
							</Button>
						</Box>
						<DataGrid
							rows={rowSession}
							columns={columnsSessions}
							pageSize={10}
							autoHeight
							disableSelectionOnClick
							filterModel={filterModel}
							onFilterModelChange={(newFilterModel) => {
								setFilterModel(newFilterModel);
							}}
							components={{ Toolbar: GridToolbar }}
							componentsProps={{
								columnMenu: {
									sx: {
										boxShadow: "none",
									},
								},
								filterPanel: {
									sx: {
										width: "1000px",
										padding: "2em",
										backgroundColor: "#201C20",
										placement: "center",
									},
								},
								panel: {
									placement: "bottom",
									sx: {
										top: "-120px !important",
										"& .MuiDataGrid-panelWrapper": {
											bgcolor: "#201C20",
										},
										"& .MuiInputBase-root": {
											width: "100px",
											marginTop: "0px",
											padding: "0px",
										},
										"& .MuiNativeSelect-select": {
											padding: "0px",
										},
									},
								},
								toolbar: {
									showQuickFilter: true,
									sx: {
										"& .MuiButton-startIcon": {
											display: "none",
										},
									},
								},
							}}
						/>
					</Box>
				</Paper>
			</Modal>
			<Modal open={isUserModalOpen} onClose={() => setIsUserModalOpen(false)}>
				<Paper>
					<Box p={2}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h2">User Details</Typography>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => setIsUserModalOpen(false)}
							>
								Close
							</Button>
						</Box>
						<TabContext value={value}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<TabList onChange={handleChange}>
									<Tab label="Account Details" value="1" />
									<Tab label="Invoices" value="2" />
									<Tab label="Logs" value="3" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<TabContext value={innerValue}>
									<TabList onChange={handleInnerChange}>
										<Tab label="User Details" value="1" />
										<Tab label="Athlete Details" value="2" />
									</TabList>

									<TabPanel value={"1"}>
										<AdminDashboardUserDetails
											filteredAthlete={filteredAthlete}
											email={filteredAthleteEmail}
										/>
									</TabPanel>
									<TabPanel value={"2"}>
										<AdminDashboardAthleteDetails
											filteredAthlete={filteredAthlete}
											trainingGoals={trainingGoals}
											experienceLevel={experienceLevel}
											athleteTrainingsGoals={athleteTrainingsGoals}
										/>
									</TabPanel>
								</TabContext>
							</TabPanel>

							<TabPanel value="2">Outer Two</TabPanel>
							<TabPanel value="3">Outer Three</TabPanel>
						</TabContext>
					</Box>
				</Paper>
			</Modal>
			<Modal
				open={isSubscriptionModalOpen}
				onClose={() => setIsSubscriptionModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: "absolute" as const,
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 500,
						bgcolor: "background.paper",
						border: "2px solid #000",
						p: 4,
					}}
				>
					{athleteSubscription &&
					athleteSubscription?.length > 0 &&
					athleteSubscription[0]?.status === "active" ? (
						<AdminDashboardPremiumSubscription
							subscriptionDetails={athleteSubscription[0]}
						/>
					) : (
						<AdminDashboardBasicSubscription
							userId={selectedUserId}
							subscriptionDetails={athleteSubscription ?? []}
						/>
					)}
				</Box>
			</Modal>
		</Box>
	);
}

import { Box, Button, Typography } from "@mui/material";
import logo from "../../assets/img/views/lock/logoLock.svg";
import { useNavigate } from "react-router-dom";
import React from "react";

export type LockScreenProps = {
	title?: string;
	titleColor?: string;
	description?: string;
	width?: string;
	useFormattedDescription?: boolean;
};

const formatDescription = (text) => {
	return text.split("/n").map((line, index) => (
		// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	));
};

export default function LockScreen({
	title,
	titleColor,
	description,
	width,
	useFormattedDescription = false,
}: LockScreenProps) {
	const navigate = useNavigate();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					paddingTop: { xs: "0.2em", xl: "0.5em" },
					paddingLeft: "1em",
					borderRadius: "10px",
					alignItems: "center",
					gap: { xl: "12px", xs: "4px" },
					height: { md: "340px", xl: "360px" },
					width: width,
					background: "linear-gradient(125deg, #3D343E 11.69%, #272727 55.18%)",
					backdropFilter: "blur(6px)",
					border: "2px solid #DD4F4A",
					margin: "0 auto",
					marginTop: "15px",
					paddingX: "3em",
				}}
			>
				<Typography variant="h3" alignSelf={"flex-start"} color={titleColor}>
					{title}
				</Typography>
				<img src={logo} alt="lock-logo" height={"100px"} width={"100px"} />
				<Typography variant="h3" fontWeight={700}>
					Premium Feature
				</Typography>
				<Typography alignSelf={"center"} textAlign={"center"} maxWidth={"80%"}>
					{useFormattedDescription
						? formatDescription(description)
						: description}
				</Typography>
				<Button
					variant="outlined"
					sx={{
						alignSelf: "center",
						marginTop: "auto",
						mb: "24px",
					}}
					onClick={() => navigate("/dashboard/settings/subscription")}
				>
					Upgrade To Premium
				</Button>
			</Box>
		</>
	);
}

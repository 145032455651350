import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	Popover,
	TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import * as yup from "yup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useSnackbar } from "notistack";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";

export type SubscriptionProps = {
	subscriptionDetails: {
		athlete_id: string;
		customer_id: string | null;
		expire_at: string | null;
		provider: string;
		start_at: string | null;
		status: string;
		subscription_id: string | null;
	}[];
	userId: string;
};
export type FormikViewType = {
	start_date: Date;
	expire_date: Date;
	provider: string;
	status: string;
};

export default function AdminDashboardBasicSubscription(
	props: SubscriptionProps,
) {
	const { enqueueSnackbar } = useSnackbar();
	const supabase = useSupabase();
	const [openStartDate, setOpenStartDate] = useState(false);
	const [openExpireDate, setOpenExpireDate] = useState(false);
	const buttonStartRef = useRef(null);
	const buttonExpireRef = useRef(null);

	const formik = useFormik<FormikViewType>({
		initialValues: {
			start_date: undefined,
			expire_date: undefined,
			provider: "admin",
			status: "active",
		},
		validationSchema: yup.object().shape({
			start_date: yup.date().required("Start Date is required"),
			expire_date: yup.date().required("Expire Date is required"),
		}),
		onSubmit: (values) => {
			if ("fire" in saveSubscriptionDatesCall) {
				saveSubscriptionDatesCall.fire(async () =>
					saveSubscriptionDatesPromise(values),
				);
			}
		},
	});

	const saveSubscriptionDatesPromise = useCallback(
		(values: FormikViewType) =>
			supabase
				.from("athlete_subscription")
				.upsert({
					athlete_id: props.userId,
					start_at: values.start_date.toISOString(),
					expire_at: values.expire_date.toISOString(),
					provider: values.provider,
					status: values.status,
				})
				.then((res) => res.data),
		[supabase],
	);

	const saveSubscriptionDatesCall =
		useAsyncState<SupabaseCall<typeof saveSubscriptionDatesPromise>>();

	useEffect(() => {
		if (isFulfilled(saveSubscriptionDatesCall)) {
			enqueueSnackbar("User is Premium", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "top",
				},
				variant: "success",
				autoHideDuration: 3000,
			});
		}
	}, [saveSubscriptionDatesCall]);

	return (
		<>
			<form noValidate onSubmit={formik.handleSubmit}>
				<Box sx={{ gap: "8px", flexDirection: "column", display: "flex" }}>
					{/* Start Date */}
					<FormControl>
						<TextField
							name="start_date"
							label="Start Date"
							error={
								formik.touched.start_date && Boolean(formik.errors.start_date)
							}
							helperText={formik.touched.start_date && formik.errors.start_date}
							value={
								formik.values.start_date
									? dayjs(formik.values.start_date).format("DD/MM/YYYY")
									: ""
							}
							InputProps={{
								readOnly: true,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setOpenStartDate(!openStartDate)}
											ref={buttonStartRef}
										>
											<CalendarMonthIcon
												sx={{ color: "#BC6D29" }}
												fontSize="small"
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</FormControl>
					<Popover
						open={openStartDate}
						anchorEl={buttonStartRef.current}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						onClose={(_) => setOpenStartDate(false)}
						PaperProps={{
							style: {
								background: "none",
							},
						}}
					>
						<Calendar
							onChange={(date) => {
								formik.setFieldTouched("start_date");
								formik.setFieldValue("start_date", date);
							}}
							date={new Date()}
						/>
					</Popover>
					{/* Expire Date */}
					<FormControl>
						<TextField
							label="Expire Date"
							name="expire_date"
							error={
								formik.touched.expire_date && Boolean(formik.errors.expire_date)
							}
							helperText={
								formik.touched.expire_date && formik.errors.expire_date
							}
							value={
								formik.values.expire_date
									? dayjs(formik.values.expire_date).format("DD/MM/YYYY")
									: ""
							}
							InputProps={{
								readOnly: true,
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setOpenExpireDate(!openExpireDate)}
											ref={buttonExpireRef}
										>
											<CalendarMonthIcon
												sx={{ color: "#BC6D29" }}
												fontSize="small"
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</FormControl>
					<Popover
						open={openExpireDate}
						anchorEl={buttonExpireRef.current}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						onClose={(_) => setOpenExpireDate(false)}
						PaperProps={{
							style: {
								background: "none",
							},
						}}
					>
						<Calendar
							onChange={(date) => {
								formik.setFieldTouched("expire_date");
								formik.setFieldValue("expire_date", date);
							}}
							date={new Date()}
						/>
					</Popover>
					<Button sx={{ mt: "8px" }} variant="contained" type="submit">
						Save
					</Button>
				</Box>
			</form>
		</>
	);
}

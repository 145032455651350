import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import FilterDown from "../../../assets/img/layout/coach/MyAthletes/FilterDown.svg";
import { useEffect, useMemo, useState } from "react";
import CoachAthleteComponent from "../MyAthletes/CoachAthleteComponent";
import { Link } from "react-router-dom";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import PreloadComponent from "src/utils/PreloadComponent";
import { useUser } from "src/components/Authenticated";
import type { Tables } from "src/utils/DatabaseDefinitions";

export type AthletesProps = {
  users: {
    athlete: {
      image?: string;
      name: string;
      user_id: string;
      max_heart_rate_session_id: string;
      max_power_session_id: string;
      athlete_experience_level: {
        experience_level: string;
      };
      session: {
        date: string;
        total_time: number
        tss: number
      }[];
      assigned_workout: Tables<'assigned_workout'>[]
    };
  }[];
};

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
        border-radius: ${theme.general.borderRadius};
  
        .MuiOutlinedInput-root {
          width:15.5rem
        } 
      `,
);

export default function CoachDashboardPreload() {
  const user = useUser();
  return (
    <>
      <PreloadComponent<{
        users: {
          athlete: {
            image?: string;
            name: string;
            user_id: string;
            max_heart_rate_session_id: string;
            max_power_session_id: string;
            athlete_experience_level: {
              experience_level: string;
            };
            session: {
              date: string;
              total_time: number
              tss: number
            }[];
            assigned_workout: Tables<'assigned_workout'>[]
          };
        }[];
      }>
        promises={{
          users: async (supabase) =>
            supabase
              .from("coach_athletes")
              .select(
                "athlete(name,image,user_id,max_heart_rate_session_id,max_power_session_id,athlete_experience_level(experience_level), session!session_athlete_id_fkey(date,total_time,tss), assigned_workout(*))",
              )
              .eq("coach_id", user.id)
              .eq("status","Accepted")
              .order('date', {foreignTable: 'athlete.assigned_workout', ascending: false})
              .order('date', {foreignTable: 'athlete.session', ascending: false})
              .then((res) => res.data),
        }}
        component={(props) => (
          <>
            <CoachAthletesView users={props.users} />
          </>
        )}
      />
    </>
  );
}

function CoachAthletesView(props: AthletesProps) {
  const [, setTitle] = useTitleCoach();
  const [search, setSearch] = useState("");
  const [menuLevel, setMenuLevel] = useState<string>("All");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setTitle("My Athletes");
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuLevel = (event) => {
    const selectedValue = event.currentTarget.getAttribute("value");
    setMenuLevel(selectedValue);
    setAnchorEl(null);
  };

  const filterUsers = useMemo(() => {
    return menuLevel === "All"
      ? props.users.filter((data) =>
          data.athlete.name.toLowerCase().includes(search.toLowerCase()),
        )
      : props.users
          .filter(
            (data) =>
              data.athlete.athlete_experience_level.experience_level ===
              menuLevel,
          )
          .filter((data) =>
            data.athlete.name.toLowerCase().includes(search.toLowerCase()),
          );
  }, [menuLevel, search]);

  return (
    <>
      <Box sx={{ padding: "2em" }}>
        <Box sx={{ justifyContent: "space-between", display: "flex" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Typography>{menuLevel}</Typography>
            <IconButton onClick={handleClick}>
              <img src={FilterDown} alt="filter-down-arrow" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={(e) => handleMenuLevel(e)}
                key="All"
                value="All"
              >
                All
              </MenuItem>
              <MenuItem
                onClick={(e) => handleMenuLevel(e)}
                key="Beginner"
                value="Beginner"
              >
                Beginner
              </MenuItem>
              <MenuItem
                onClick={(e) => handleMenuLevel(e)}
                key="Intermediate"
                value="Intermediate"
              >
                Intermediate
              </MenuItem>
              <MenuItem
                onClick={(e) => handleMenuLevel(e)}
                key="Advanced"
                value="Advanced"
              >
                Advanced
              </MenuItem>
              <MenuItem
                onClick={(e) => handleMenuLevel(e)}
                key="Expert"
                value="Expert"
              >
                Expert
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            <SearchInputWrapper
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon sx={{ opacity: "0.5" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "15.5rem",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#DD4F4A",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#DD4F4A",
                },
              }}
              placeholder="Search Athlete"
              onKeyDown={(e) => e.code === "Enter"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        </Box>
        <Grid container gap={1} mt={"1rem"}>
          {filterUsers.map((athleteData, index) => (
            <Grid
              item
              lg={2.9}
              md={5.9}
              xs={11.5}
              key={athleteData.athlete.user_id}
              component={Link}
              to={`/coach/my_athletes/${athleteData.athlete.user_id}/overview`}
              sx={{ color: "#F7F3F0", textDecoration: "none" }}
            >
              <CoachAthleteComponent {...athleteData.athlete} />
            </Grid>
          ))}
        </Grid>
      </Box>

     
    </>
  );
}

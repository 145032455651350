import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "src/content/customLayouts/DashboardLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import { useSupabase } from "src/contexts/SupabaseContext";
import type { SupabaseCall } from "src/utils/common";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import dayjs from "dayjs";
import { useUser } from "src/components/Authenticated";

type StatusBarProps = {
  goalTarget: number;
  goalDays: number;
  planValue: number;
};

export default function StatusBar(props: StatusBarProps) {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const subscriptionPromise = useCallback(
    () =>
      supabase
        .from("athlete_subscription")
        .select("*")
        .eq("athlete_id", user.id)
        .then((res) => res.data[0]),
    [supabase],
  );

  const subscriptionCall =
    useAsyncState<SupabaseCall<typeof subscriptionPromise>>();

  // Loading effect
  useEffect(() => {
    if (isUnloaded(subscriptionCall)) {
      subscriptionCall.fire(async () => subscriptionPromise());
    }
  }, [subscriptionCall, subscriptionPromise]);

  const isPremium = useMemo(() => {
    if (isFulfilled(subscriptionCall)) {
      return subscriptionCall?.result?.status === "active";
    }
  }, [subscriptionCall]);
  const [title] = useTitle();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 950);
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 950);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sessionId = useMemo(() => {
    const pathParts = location.pathname.split("/");
    const sessionIndex = pathParts.indexOf("session");
    return pathParts[sessionIndex + 1];
  }, [location.pathname]);

  const [sessions, setSessions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const sessionsPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("id")
        .eq("athlete_id", user.id)
        .order("date", { ascending: false })
        .then((res) => setSessions(res.data)),
    [supabase, sessionId],
  );

  const sessionsCall =
  useAsyncState<SupabaseCall<typeof sessionsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(sessionsCall)) {
      sessionsCall.fire(async () => sessionsPromise());
    }
  }, [supabase, sessionsCall]);

  useEffect(() => {
    const currentIndex = sessions.findIndex(
      (session) => session.id === sessionId,
    );
    setCurrentIndex(currentIndex);
  }, [sessions, sessionId]);

  // const sessionNextPromise = useCallback(
  //   () =>
  //     supabase
  //       .from("session")
  //       .select("*")
  //       .eq("id", sessionId)
  //       .then((res) =>
  //         supabase
  //           .from("session")
  //           .select("id,date")
  //           .gte("date", dayjs(res.data[0].date))
  //           .limit(2)
  //           .order("date", { ascending: true })
  //           .then((res) => {
  //             console.log(res.data);
  //             setNextSessionId(res.data[1]?.id ?? "");
  //           }),
  //       ),
  //   [supabase, sessionId],
  // );

  // const sessionNext =
  // useAsyncState<SupabaseCall<typeof sessionNextPromise>>(); // prettier-ignore

  // useEffect(() => {
  //   if (isUnloaded(sessionNext) && sessionId !== "") {
  //     sessionNext.fire(async () => sessionNextPromise());
  //   }
  // }, [supabase, sessionNext, sessionId]);

  // const sessionPreviousPromise = useCallback(
  //   () =>
  //     supabase
  //       .from("session")
  //       .select("*")
  //       .eq("id", sessionId)
  //       .then((res) => {
  //         supabase
  //           .from("session")
  //           .select("id,date")
  //           .order("date", { ascending: false })
  //           .lt("date", dayjs(res.data[0].date))
  //           .limit(2)
  //           .then((res) => setPreviousSessionId(res.data[0]?.id ?? ""));
  //       }),
  //   [supabase, sessionId],
  // );

  // const sessionPrevious =
  // useAsyncState<SupabaseCall<typeof sessionPreviousPromise>>(); // prettier-ignore

  // useEffect(() => {
  //   if (isUnloaded(sessionPrevious) && sessionId !== "") {
  //     sessionPrevious.fire(async () => sessionPreviousPromise());
  //   }
  // }, [supabase, sessionPrevious, sessionId]);

  // useEffect(() => {
  //   if (!location.pathname.startsWith("/dashboard/session/")) {
  //     console.log("t");
  //     // if (isFulfilled(sessionNext)) sessionNext.unload();
  //     // if (isFulfilled(sessionPrevious)) sessionPrevious.unload();
  //     if (isFulfilled(sessionsCall)) sessionsCall.unload();
  //   }
  // }, [location.pathname]);

  // Upload fit files

  const uploadFitFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    supabase.functions.invoke("upload-fit-file", {
      method: "POST",
      body: formData,
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        await uploadFitFile(file);
      } catch (error) {
        console.error("Error uploading file:", error.message);
      }
    }
  };

  return (
    <>
      {isWideScreen ? (
        <Box
          sx={{
            backgroundColor: "#201B20",
            height: "50px",
            paddingX: "24px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "32px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              fontSize={"1.25rem"}
              color="text.secondary"
            >
              {title}
            </Typography>
            {/* {location.pathname === "/dashboard" ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography variant="h5">NEXT GOAL</Typography>
                <Typography
                  variant="h5"
                  color="text.secondary"
                >{`${props.goalTarget} km `}</Typography>
                <Typography variant="h4">in {props.goalDays} days</Typography>
                <Typography variant="h5">PLAN COMPLIANCE</Typography>
                <Typography variant="h5" color="text.secondary">
                  {props.planValue}%
                </Typography>
              </Box>
            ) : null} */}
          </Box>
          {location.pathname === "/dashboard" ||
          location.pathname === "/dashboard/sessions" ? (
            isPremium && (
              <Button variant="contained" size="small" component={"label"}>
                Import
                <input
                  type="file"
                  hidden
                  accept=".fit"
                  onChange={handleFileChange}
                />
              </Button>
            )
          ) : location.pathname.startsWith("/dashboard/session/") ? (
            <Box>
              <IconButton
                color="primary"
                disabled={currentIndex === sessions.length}
                onClick={(_) => {
                  navigate(
                    `/dashboard/session/${
                      sessions[currentIndex + 1].id
                    }/overview`,
                  );
                  navigate(0);
                }}
              >
                <ArrowBackTwoToneIcon fontSize="medium" />
              </IconButton>
              <IconButton
                color="primary"
                disabled={currentIndex === 0}
                onClick={(_) => {
                  navigate(
                    `/dashboard/session/${
                      sessions[currentIndex - 1].id
                    }/overview`,
                  );
                  navigate(0);
                }}
              >
                <ArrowForwardTwoToneIcon fontSize="medium" />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <>
          {location.pathname === "/dashboard" ||
          location.pathname === "/dashboard/sessions" ? (
            <>
              <Box
                sx={{
                  backgroundColor: "#201B20",
                  paddingX: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h2"
                  fontSize={"1.25rem"}
                  color="text.secondary"
                >
                  {title}
                </Typography>
                {/* <Button variant="contained" size="small" component={"label"}>
                  Import
                  <input type="file" hidden />
                </Button> */}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                backgroundColor: "#201B20",
                height: isMobileScreen ? "80px" : "50px",
                paddingX: "24px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: "32px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h2"
                  fontSize={"1.25rem"}
                  color="text.secondary"
                >
                  {title}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

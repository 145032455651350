import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import FilterDown from "../../../assets/img/layout/coach/MyAthletes/FilterDown.svg";
import { useUser } from "src/components/Authenticated";
import PreloadComponent from "src/utils/PreloadComponent";
import leftArr from "../../../assets/img/layout/coach/leftArr.svg";
import rightArr from "../../../assets/img/layout/coach/rightArr.svg";
import dayjs from "dayjs";
import { useSupabase } from "src/contexts/SupabaseContext";
import Chart from "react-apexcharts";
import type { ApexOptions } from "apexcharts";
import CoachAthlComponentDashboard from "../customComponents/CoachAthlComponentDashboard";
import CoachCalendarWidget from "../customComponents/CoachCalendarWidget";
import { useTitleCoach } from "../Dashboard/CoachDashboardLayout";
import { useNavigate } from "react-router-dom";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import type { Tables } from "src/utils/DatabaseDefinitions";

export type AthletesProps = {
	users: {
		athlete: {
			name: string;
			user_id: string;
			max_heart_rate_session_id: string;
			max_power_session_id: string;
			athlete_experience_level: {
				experience_level: string;
			};
			session: {
				date: string;
				recording_name: string;
				total_distance: number;
				total_time: number;
				tss: number;
				workload: number;
			}[];
			assigned_workout: Tables<"assigned_workout">[];
		};
	}[];
};

export default function CoachDashboardPreload() {
	const user = useUser();
	return (
		<>
			<PreloadComponent<{
				users: {
					athlete: {
						image?: string;
						name: string;
						user_id: string;
						max_heart_rate_session_id: string;
						max_power_session_id: string;
						athlete_experience_level: {
							experience_level: string;
						};
						session: {
							date: string;
							recording_name: string;
							total_distance: number;
							total_time: number;
							tss: number;
							workload: number;
						}[];
						assigned_workout: Tables<"assigned_workout">[];
					};
				}[];
			}>
				promises={{
					users: async (supabase) =>
						supabase
							.from("coach_athletes")
							.select(
								"athlete(name,image,user_id,max_heart_rate_session_id,max_power_session_id,athlete_experience_level(experience_level), session!session_athlete_id_fkey(date,recording_name,total_time,total_distance,tss,workload), assigned_workout(*))",
							)
							.eq("coach_id", user.id)
							.eq("status", "Accepted")
							.order("date", {
								foreignTable: "athlete.assigned_workout",
								ascending: false,
							})
							.order("date", {
								foreignTable: "athlete.session",
								ascending: false,
							})
							//.eq("status", 3)
							.then((res) => res.data),
				}}
				component={(props) => (
					<>
						<CoachDashboardView users={props.users} />
					</>
				)}
			/>
		</>
	);
}

function CoachDashboardView(props: AthletesProps) {
	const [, setTitle] = useTitleCoach();
	const supabase = useSupabase();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobileScreen = useMediaQuery("(max-width:1185px)");
	const units = useUnits();
	const currentDate = dayjs();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [menuLevel, setMenuLevel] = useState("All");
	const [selectedAthlete, setSelectedAthlete] = useState<{
		image?: string;
		name: string;
		user_id: string;
		max_heart_rate_session_id: string;
		max_power_session_id: string;
		athlete_experience_level: {
			experience_level: string;
		};
	}>();
	const timeFormatter = useCallback((valStr) => {
		const val = Number(valStr);
		const hours = Math.floor(Math.floor(val / 60) / 60);
		const minutes = Math.trunc((val % 3600) / 60);
		const seconds = Math.trunc(val % 60);
		return `${hours === 0 ? "00" : hours < 10 ? "0" + hours : hours}:${
			minutes === 0 ? "00" : minutes < 10 ? "0" + minutes : minutes
		}:${seconds === 0 ? "00" : seconds < 10 ? "0" + seconds : seconds}`;
	}, []);

	useEffect(() => {
		setTitle("Dashboard");
	}, []);

	const handleBoxClick = (index: number) => {
		if (index < filterUsers.length) setSelectedIndex(index);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuLevel = (event) => {
		const selectedValue = event.currentTarget.getAttribute("value");
		setMenuLevel(selectedValue);
		setAnchorEl(null);
		setSelectedIndex(0);
	};

	const [calendarDate, setCalendarDate] = useState(
		currentDate.startOf("week").add(1, "day"),
	);

	const handlePreviousDate = useCallback(() => {
		setCalendarDate((prev) => prev.subtract(1, "week"));
	}, []);
	const handleNextDate = useCallback(() => {
		setCalendarDate((prev) => prev.add(1, "week"));
	}, []);

	const filterUsers = useMemo(() => {
		return menuLevel === "All"
			? props.users
			: props.users.filter(
					(data) =>
						data.athlete.athlete_experience_level.experience_level ===
						menuLevel,
				);
	}, [menuLevel]);

	const paddedUsers: {
		athlete: {
			image?: string;
			name: string;
			user_id: string;
			max_heart_rate_session_id: string;
			max_power_session_id: string;
			athlete_experience_level: {
				experience_level: string;
			};
			session: {
				date: string;
				recording_name: string;
				total_distance: number;
				total_time: number;
				tss: number;
				workload: number;
			}[];
			assigned_workout: Tables<"assigned_workout">[];
		};
	}[] = useMemo(() => {
		return filterUsers.length <= 15
			? [
					...filterUsers,
					...Array(Math.max(0, 15 - filterUsers.length)).fill({
						athlete: null,
					}),
				]
			: [
					...filterUsers,
					...Array(
						Math.max(
							0,
							(filterUsers.length - 15) % 3 === 1
								? 2
								: (filterUsers.length - 15) % 3 === 2
									? 1
									: 0,
						),
					).fill({ athlete: null }),
				];
	}, [filterUsers]);

	useEffect(() => {
		if (filterUsers.length === 0) {
			setSelectedIndex(-1);
		} else setSelectedIndex(selectedIndex);
		setSelectedAthlete(paddedUsers[selectedIndex]?.athlete);
	}, [selectedIndex, paddedUsers]);

	const calendarSessions = useMemo(() => {
		const athlete = props.users.find(
			(user) => user.athlete.user_id === selectedAthlete?.user_id,
		);
		return athlete?.athlete.session.filter(
			(session) =>
				calendarDate.isSameOrBefore(new Date(session.date), "day") &&
				calendarDate
					.endOf("week")
					.add(1, "day")
					.isSameOrAfter(new Date(session.date), "day"),
		);
	}, [selectedAthlete, calendarDate]);

	const calendarAssignedWorkouts = useMemo(() => {
		const athlete = props.users.find(
			(user) => user.athlete.user_id === selectedAthlete?.user_id,
		);
		return athlete?.athlete.assigned_workout.filter(
			(workout) =>
				calendarDate.isSameOrBefore(new Date(workout.date), "day") &&
				calendarDate
					.endOf("week")
					.add(1, "day")
					.isSameOrAfter(new Date(workout.date), "day"),
		);
	}, [selectedAthlete, calendarDate]);

	const compliance = useMemo(() => {
		const compliance = [];
		let daysWithWorkout = 0;
		let targetMeetDays = 0;

		for (let i = 0; i < 7; i++) {
			if (currentDate < calendarDate.add(i, "day")) {
				compliance[calendarDate.add(i, "day").format("YYYY-MM-DD")] = {
					date: calendarDate.add(i, "day").format("YYYY-MM-DD"),
					status: "todo",
				};
				continue;
			}
			const dayWorkouts = calendarAssignedWorkouts?.filter((workout) =>
				dayjs(workout.date).isSame(calendarDate.add(i, "day"), "day"),
			);

			if (dayWorkouts?.length === 0) {
				compliance[calendarDate.add(i, "day").format("YYYY-MM-DD")] = {
					date: calendarDate.add(i, "day").format("YYYY-MM-DD"),
					status: "nothing",
				};
				continue;
			}
			const targetWorkoutInfo = dayWorkouts?.reduce(
				(acc, curr) => ({
					duration: acc.duration + curr.duration,
					tss: acc.tss + curr.tss,
				}),
				{ duration: 0, tss: 0 },
			);
			const daySessionInfo = calendarSessions?.reduce(
				(acc, curr) => {
					if (dayjs(curr.date).isSame(calendarDate.add(i, "day"), "day")) {
						return {
							duration: acc.duration + curr.total_time / 1000,
							tss: acc.tss + curr.tss,
						};
					}
					return acc;
				},
				{ duration: 0, tss: 0 },
			);

			if (
				daySessionInfo?.duration / targetWorkoutInfo?.duration >= 0.75 &&
				daySessionInfo.tss * (1 / targetWorkoutInfo.tss) >= 0.75
			) {
				compliance[calendarDate.add(i, "day").format("YYYY-MM-DD")] = {
					date: calendarDate.add(i, "day").format("YYYY-MM-DD"),
					status: "target meet",
				};
				daysWithWorkout += 1;
				targetMeetDays += 1;
				continue;
			}
			compliance[calendarDate.add(i, "day").format("YYYY-MM-DD")] = {
				date: calendarDate.add(i, "day").format("YYYY-MM-DD"),
				status: "skipped",
			};
			daysWithWorkout += 1;
			continue;
		}
		const complianceArray = Object.values(compliance);
		return { complianceArray, targetMeetDays, daysWithWorkout };
	}, [calendarAssignedWorkouts, calendarSessions]);

	const { totalDuration, totalDistance, tss } = useMemo(() => {
		return (calendarSessions || []).reduce(
			(accumulator, currentValue) => {
				return {
					totalDuration:
						accumulator.totalDuration + (currentValue?.total_time || 0),
					totalDistance:
						accumulator.totalDistance + (currentValue?.total_distance || 0),
					tss: accumulator.tss + (currentValue?.tss || 0),
				};
			},
			{ totalDuration: 0, totalDistance: 0, tss: 0 },
		);
	}, [calendarSessions]);

	const combinedData: {
		date: Date;
		workload: number;
		total_time: number;
	}[] = useMemo(() => {
		if (!!calendarSessions) {
			const mySessions = [];
			for (let i = 0; i < 7; i++) {
				mySessions[calendarDate.add(i, "day").format("YYYY-MM-DD")] = {
					date: calendarDate.add(i, "day").format("YYYY-MM-DD"),
					total_time: 0,
					workload: 0,
				};
				calendarSessions?.forEach((session) => {
					if (dayjs(session.date).isSame(calendarDate.add(i, "day"), "day")) {
						mySessions[
							calendarDate.add(i, "day").format("YYYY-MM-DD")
						].total_time += session.total_time;
						mySessions[
							calendarDate.add(i, "day").format("YYYY-MM-DD")
						].workload += session.workload;
					}
				});
			}
			const aggregatedSessionsArray = Object.values(mySessions);
			return aggregatedSessionsArray;
		}
	}, [calendarDate, calendarSessions]) as any;
	// Volume-Compliance Data

	const combinedChartData = {
		series: [
			{
				name: "Volume",
				type: "bar",
				data: combinedData?.map((data) => data.total_time) ?? [],
			},
			{
				name: "Workload",
				type: "line",
				data: combinedData?.map((data) => data.workload) ?? [],
			},
		],
	};

	const chartOptions: ApexOptions = useMemo(
		() => ({
			chart: {
				fontFamily: "Arial, sans-serif",
				toolbar: { show: false },
				background: "transparent",
				zoom: { enabled: false },
			},
			xaxis: {
				categories: combinedData?.map(
					(data) => dayjs(data.date).format("dd")[0],
				),
				labels: {
					show: true,
					style: {
						fontSize: "14px",
						colors: "#64605D",
					},
				},
				axisTicks: { show: false },
				axisBorder: {
					color: "#64605D",
				},
				tooltip: {
					enabled: false,
				},
			},
			colors: ["#DD4F4A", "#F7F3F0"],
			yaxis: [
				{
					labels: {
						show: false,
						formatter: function (value) {
							return timeFormatter(value / 1000);
						},
					},
				},
				{
					labels: {
						show: true,
						formatter: function (value) {
							return value.toFixed(1) + " Wo";
						},
					},
					axisBorder: {
						show: true,
						color: "#64605D",
					},
					tickAmount: 3,
				},
			],
			plotOptions: {
				bar: {
					horizontal: false,
				},
			},

			tooltip: {
				x: {
					formatter: function (
						value,
						{ series, seriesIndex, dataPointIndex, w },
					) {
						return dayjs(combinedData[dataPointIndex].date).format("DD/MM");
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			markers: {
				hover: {
					sizeOffset: 2,
				},
				shape: "circle",
				size: 3,
				strokeWidth: 3,
				strokeOpacity: 1,
			},
			fill: {
				opacity: 1,
			},
			legend: {
				show: true,
				onItemClick: {
					toggleDataSeries: false,
				},
			},
			grid: {
				show: false,
			},
			stroke: {
				width: 3,
				curve: "smooth",
			},
			theme: {
				mode: theme.palette.mode,
			},
			height: "auto",
		}),
		[combinedData],
	);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-around",
				flexDirection: isMobileScreen ? "column" : "row",
				margin: 1,
			}}
		>
			{/* Left Box */}
			<Box
				sx={{
					background: "#2C262D",
					borderRadius: "10px",
					width: isMobileScreen ? "100%" : "60%",
					padding: "1.3em",
					marginBottom: isMobileScreen ? "1rem" : "",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box sx={{ display: "flex", gap: 5 }}>
						<Typography>OVERVIEW</Typography>
						<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
							<Typography>TOTAL ATHLETES:</Typography>
							<Typography sx={{ color: "#DD4F4A" }}>
								{filterUsers.length}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							cursor: "pointer",
						}}
					>
						<Typography>{menuLevel}</Typography>
						<IconButton onClick={handleClick}>
							<img src={FilterDown} alt="filter-down-arrow" />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={() => setAnchorEl(null)}
						>
							<MenuItem
								onClick={(e) => handleMenuLevel(e)}
								key="All"
								value="All"
							>
								All
							</MenuItem>
							<MenuItem
								onClick={(e) => handleMenuLevel(e)}
								key="Beginner"
								value="Beginner"
							>
								Beginner
							</MenuItem>
							<MenuItem
								onClick={(e) => handleMenuLevel(e)}
								key="Intermediate"
								value="Intermediate"
							>
								Intermediate
							</MenuItem>
							<MenuItem
								onClick={(e) => handleMenuLevel(e)}
								key="Advanced"
								value="Advanced"
							>
								Advanced
							</MenuItem>
							<MenuItem
								onClick={(e) => handleMenuLevel(e)}
								key="Expert"
								value="Expert"
							>
								Expert
							</MenuItem>
						</Menu>
					</Box>
				</Box>
				<Typography sx={{ color: "#64605D", fontSize: "10px" }}>
					{calendarDate.format("DD/MM")} - {currentDate.format("DD/MM")}
				</Typography>
				<Grid
					container
					marginY="1rem"
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						maxHeight: { md: "525px", xl: "850px" },
						overflowY: filterUsers.length > 15 ? "auto" : "hidden",
						marginBottom: { xl: "2.5rem" },
					}}
				>
					{paddedUsers.map((athleteData, index) => (
						<Grid
							item
							key={index}
							onClick={() => handleBoxClick(index)}
							sx={{
								textDecoration: "none",
								cursor: index < filterUsers.length ? "pointer" : "default",
								backgroundColor:
									index === selectedIndex ? "#726174" : "transparent",
							}}
						>
							<Box
								sx={{
									color: "#F7F3F0",
									textDecoration: "none",
									height: { md: "105px", xl: "170px" },
									border: "1px solid #64605D",
									boxSizing: "border-box",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{athleteData.athlete && (
									<CoachAthlComponentDashboard
										{...athleteData.athlete}
										calendarDate={calendarDate}
									/>
								)}
							</Box>
						</Grid>
					))}
				</Grid>
				<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#FF5630",
								width: "20px",
								height: "5px",
							}}
						></Box>
						<Typography>SKIPPED</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#36B37E",
								width: "20px",
								height: "5px",
							}}
						></Box>
						<Typography>TARGET MEET</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#3b3b3b",
								width: "20px",
								height: "5px",
							}}
						></Box>
						<Typography>NOTHING</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: 1,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								backgroundColor: "#4367E7",
								width: "20px",
								height: "5px",
							}}
						></Box>
						<Typography>TO DO</Typography>
					</Box>
				</Box>
			</Box>
			{/* Right Box */}
			<Box
				sx={{
					background: "#2C262D",
					borderRadius: "10px",
					width: isMobileScreen ? "100%" : "35%",
					padding: "1em",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography>VOLUME-COMPLIANCE</Typography>
					<Typography sx={{ color: "#64605D", fontSize: "10px" }}>
						{calendarDate.format("DD/MM")} -{" "}
						{calendarDate.add(6, "day").format("DD/MM")}
					</Typography>
				</Box>
				<Typography variant="body2" marginBottom={"1rem"}>
					{selectedAthlete?.name}
				</Typography>
				<Box
					sx={{
						border: "1px solid #64605D",
						borderRadius: "6px",
						width: "95%",
						height: { md: "270px", xl: "400px" },
						margin: "0 auto",
					}}
				>
					{filterUsers.length === 0 ? (
						<Chart
							options={chartOptions}
							series={[]}
							type="line"
							height={"80%"}
						/>
					) : (
						<Chart
							options={chartOptions}
							series={combinedChartData.series}
							type="line"
							height={"80%"}
						/>
					)}
					<Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
						<Box sx={{ display: "flex", gap: { md: 3, xl: 6 } }}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
									Duration
								</Typography>
								<Typography variant="subtitle1" color={"#DD4F4A"}>
									{timeFormatter(totalDuration / 1000) ?? "-"}
								</Typography>
							</Box>
							<Divider
								orientation="vertical"
								sx={{ height: "30px", background: "#64605D" }}
							/>
						</Box>
						<Box sx={{ display: "flex", gap: { md: 3, xl: 6 } }}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
									Distance
								</Typography>
								<Typography variant="subtitle1" color={"#DD4F4A"}>
									{units === "metric"
										? `${totalDistance.toFixed(2) ?? "0"} km`
										: `${
												totalDistance
													? convert(totalDistance)
															.from("km")
															.to("mi")
															.toFixed(2)
													: "0"
											} mi`}
								</Typography>
							</Box>
							<Divider
								orientation="vertical"
								sx={{ height: "30px", background: "#64605D" }}
							/>
						</Box>
						<Box sx={{ display: "flex", gap: { md: 3, xl: 6 } }}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
									TSS
								</Typography>
								<Typography variant="subtitle1" color={"#DD4F4A"}>
									{tss?.toFixed(2) ?? "-"}
								</Typography>
							</Box>
							<Divider
								orientation="vertical"
								sx={{ height: "30px", background: "#64605D" }}
							/>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography sx={{ fontSize: "11px", fontWeight: 400 }}>
									Compliance
								</Typography>
								<Typography variant="subtitle1" color={"#DD4F4A"}>
									{compliance.daysWithWorkout === 0
										? "-"
										: (
												(compliance.targetMeetDays /
													compliance.daysWithWorkout) *
												100
											).toFixed(1)}
									%
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						marginTop: "0.5rem",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography>WEEKLY CALENDAR</Typography>
					<Box>
						<IconButton sx={{ padding: "1px" }} onClick={handlePreviousDate}>
							<img src={leftArr} alt="left-arrow" />
						</IconButton>
						<IconButton sx={{ padding: "1px" }} onClick={handleNextDate}>
							<img src={rightArr} alt="right-arrow" />
						</IconButton>
					</Box>
				</Box>
				{filterUsers.length === 0 ? (
					<CoachCalendarWidget
						date_session={[]}
						startOfWeek={calendarDate}
						endOfWeek={calendarDate.endOf("week").add(1, "day")}
					/>
				) : (
					<CoachCalendarWidget
						date_session={calendarSessions}
						startOfWeek={calendarDate}
						endOfWeek={calendarDate.endOf("week").add(1, "day")}
					/>
				)}

				<Box
					sx={{
						marginTop: "0.2rem",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Button
						variant="coachVariant"
						size="small"
						onClick={() =>
							navigate(
								`/coach/my_athletes/${selectedAthlete?.user_id}/overview`,
							)
						}
						disabled={!selectedAthlete}
					>
						View Athlete
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ConnectAthlete from "../MyAthletes/ConnectAthlete";
import { useTitleCoach } from "./CoachDashboardLayout";

export default function CoachStatusBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const [title] = useTitleCoach();

	return (
		<>
			<Box
				sx={{
					backgroundColor: "#201B20",
					height: "50px",
					paddingX: "24px",
					display: "flex",
					gap: "10px",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						columnGap: "32px",
						alignItems: "center",
					}}
				>
					<Typography variant="coach700" fontSize={"1.25rem"}>
						{title}
					</Typography>
				</Box>
				{location.pathname !== "/coach/my_athletes" &&
				!location.pathname.includes("/coach/requests") ? (
					<Button
						variant="coachVariant"
						size="small"
						onClick={() => navigate("/coach/my_athletes")}
					>
						Manage Athletes
					</Button>
				) : (
					<ConnectAthlete />
				)}
			</Box>
		</>
	);
}

import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import type { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import { useSnackbar } from "notistack";
import useAsyncState, { isFulfilled } from "src/utils/Async";

export type CoachComponentProps = {
	coach: {
		country_code: string;
		email: string;
		expertise: string[];
		gender: boolean;
		image: string | null;
		name: string;
		other_info: string | null;
		phone: string | null;
		qualifications: string;
		units: boolean;
		user_id: string;
	};
	countries: {
		code: string;
		id: number;
		name: string;
		phone: number[] | null;
	}[];
};

export default function CoachRequestCard(props: CoachComponentProps) {
	const supabase = useSupabase();
	const user = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const avatar = useMemo(() => {
		if (props.coach.image) {
			return supabase.storage.from("avatars").getPublicUrl(props.coach.image, {
				transform: {
					width: 50,
					height: 50,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 50,
			radius: 55,
			seed: props.coach.name,
		}).toDataUriSync();
	}, [props]);

	console.log(props);

	// Accept Request

	const acceptConnectionRequestPromise = useCallback(() => {
		return supabase
			.from("coach_athletes")
			.update({
				status: "Accepted",
			})
			.throwOnError()
			.eq("athlete_id", user.id)
			.eq("coach_id", props.coach.user_id)
			.then(() => {
				if ("fire" in acceptConnectionNotificationCall) {
					acceptConnectionNotificationCall.fire(async () =>
						acceptConnectionNotificationPromise(props.coach.user_id),
					);
				}
			});
	}, [supabase]);

	const acceptConnectionRequestCall =
		useAsyncState<SupabaseCall<typeof acceptConnectionRequestPromise>>();

	useEffect(() => {
		if (isFulfilled(acceptConnectionRequestCall)) {
			enqueueSnackbar("Connection request accepted!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "top",
				},
				variant: "success",
				autoHideDuration: 2000,
			});
		}
	}, [acceptConnectionRequestCall]);

	const acceptConnectionNotificationPromise = useCallback(
		async (userId: string) => {
			return supabase.from("notifications").insert({
				type: "Connection Accepted",
				content: {
					description: `Athlete ${user.user_metadata.name} (${user.email}) has accepted your connection request.`,
				},
				user_id: userId,
			});
		},
		[supabase, user],
	);

	const acceptConnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof acceptConnectionNotificationPromise>>();

	// Reject Request

	const rejectRequestPromise = useCallback(
		() =>
			supabase
				.from("coach_athletes")
				.delete()
				.eq("athlete_id", user.id)
				.eq("coach_id", props.coach.user_id)
				.throwOnError()
				.then(() => {
					if ("fire" in rejectConnectionNotificationCall) {
						rejectConnectionNotificationCall.fire(async () =>
							rejectConnectionNotificationPromise(props.coach.user_id),
						);
					}
				}),
		[supabase],
	);

	const rejectRequestCall =
		useAsyncState<SupabaseCall<typeof rejectRequestPromise>>();

	const rejectConnectionNotificationPromise = useCallback(
		async (userId: string) => {
			return supabase
				.from("notifications")
				.insert({
					type: "Connection Rejected",
					content: {
						description: `Athlete ${user.user_metadata.name} (${user.email}) has rejected your connection request.`,
					},
					user_id: userId,
				})
				.then((_) => {
					enqueueSnackbar("Connection request rejected!", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "top",
						},
						variant: "success",
						autoHideDuration: 2000,
					});
				});
		},
		[supabase, user],
	);

	const rejectConnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof rejectConnectionNotificationPromise>>();

	return (
		<>
			<Box
				sx={{
					display: "flex",
					gap: 3,
					padding: 2,
					background: "#2C262D",
					borderRadius: 5,
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
					<img
						src={avatar}
						alt="avatar"
						style={{
							borderRadius: "50%",
							width: "50px",
							height: "50px",
							objectFit: "cover",
						}}
					/>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
						<Typography fontSize={".875rem"}>{props.coach.name}</Typography>
						<Typography fontSize={".75rem"} sx={{ opacity: 0.5 }}>
							{
								props.countries.find(
									(country) => country.code === props.coach.country_code,
								).name
							}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
					<Button
						onClick={() => {
							if ("fire" in rejectRequestCall) {
								rejectRequestCall.fire(async () =>
									rejectRequestPromise().then((r) => {
										return r;
									}),
								);
							}
						}}
					>
						Reject
					</Button>
					<Button
						onClick={() => {
							if ("fire" in acceptConnectionRequestCall) {
								acceptConnectionRequestCall.fire(async () =>
									acceptConnectionRequestPromise(),
								);
							}
						}}
					>
						Accept
					</Button>
				</Box>
			</Box>
		</>
	);
}

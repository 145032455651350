import { Grid } from "@mui/material";
import TrendUp from "../../../assets/img/views/dashboard-widgets/TrendUp.svg";
import TrendDown from "../../../assets/img/views/dashboard-widgets/TrendDown.svg";
import NoChange from "../../../assets/img/views/dashboard-widgets/NoChange.svg";
import dayjs from "dayjs";
import { useMemo } from "react";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export type Trend =
  | "position_score"
  | "efficiency_factor"
  | "effort_score"
  | "normalized_power"
  | "intensity_factor"
  | "speed_efficiency"
  | "tss"
  | "variability_index"
  | "workload";

export type TrendsWidgetProps = {
  sessions: {
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    workload: number;
    tss: number;
    date: string;
  }[];
};

export type TrendsImageProps = {
  trend: string;
  yearBucket: {
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    workload: number;
    tss: number;
  };
  lastMonthsBucket: {
    efficiency_factor: number;
    effort_score: number;
    normalized_power: number;
    intensity_factor: number;
    workload: number;
    tss: number;
  };
};

function TrendImage(props: TrendsImageProps) {
  return props.lastMonthsBucket[props.trend] > props.yearBucket[props.trend] ? (
    <img src={TrendUp} alt="trendup-icon" width={"20px"} />
  ) : props.lastMonthsBucket[props.trend] < props.yearBucket[props.trend] ? (
    <img src={TrendDown} alt="trendDown-icon" width={"20px"} />
  ) : (
    <img src={NoChange} alt="stale-icon" width={"20px"} />
  );
}

export default function TrendsWidget(props: TrendsWidgetProps) {
  const allTrends = [
    "efficiency_factor",
    "effort_score",
    "normalized_power",
    "intensity_factor",
    "workload",
    "tss",
  ];

  const rangeHigh = dayjs();
  const rangeLow = rangeHigh.subtract(365, "days");

  // finding how many days is the last week
  const lastWeek = useMemo(() => {
    const indexOfDay = rangeHigh.isoWeekday();
    const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return [...days.slice(0, indexOfDay)];
  }, [rangeHigh]);

  // new range high removed last week
  const newRangeHigh = useMemo(() => {
    return rangeHigh.subtract(lastWeek.length, "day");
  }, [rangeHigh]);

  const newRangeLow = useMemo(() => {
    return newRangeHigh.subtract(11, "week").subtract(6, "day");
  }, [rangeLow]);

  // sessions between new low and new high
  const filterSessions = useMemo(() => {
    return props.sessions.filter(
      (session) =>
        newRangeLow.isSameOrBefore(new Date(session.date), "day") &&
        rangeHigh.isSameOrAfter(new Date(session.date), "day"),
    );
  }, [newRangeLow, rangeHigh]);

  const yearBucket = useMemo(() => {
    const sumObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    const countObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    for (const session of props.sessions) {
      allTrends.map((trend, index) => {
        if (session[trend] !== 0 && session[trend] !== null) {
          sumObject[trend] += session[trend];
          countObject[trend] += 1;
        }
      });
    }
    return Object.fromEntries(
      Object.entries(sumObject).map(([trend, val]) => [
        trend,
        val === 0 ? val : (val / countObject[trend]).toFixed(2),
      ]),
    ) as { [K in Trend]: number };
  }, [props.sessions]);

  const lastMonthsBucket = useMemo(() => {
    const sumObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    const countObject = Object.fromEntries(
      allTrends.map((trend) => [trend, 0]),
    ) as { [K in Trend]: number };
    for (const session of filterSessions) {
      allTrends.map((trend, index) => {
        if (session[trend] !== 0 && session[trend] !== null) {
          sumObject[trend] += session[trend];
          countObject[trend] += 1;
        }
      });
    }
    return Object.fromEntries(
      Object.entries(sumObject).map(([trend, val]) => [
        trend,
        val === 0 ? val : (val / countObject[trend]).toFixed(2),
      ]),
    ) as { [K in Trend]: number };
  }, [filterSessions]);

  const trendsInfo = useMemo(
    () => [
      {
        index: 1,
        title: "Efficiency Factor",
        metric: `${lastMonthsBucket["efficiency_factor"]}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "efficiency_factor",
        }),
      },
      {
        index: 2,
        title: "Effort Score",
        metric: `${lastMonthsBucket["effort_score"]}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "effort_score",
        }),
      },
      {
        index: 3,
        title: "Normalised Power®",
        metric: `${lastMonthsBucket["normalized_power"]}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "normalized_power",
        }),
      },
      {
        index: 4,
        title: "Intensity Factor",
        metric: `${lastMonthsBucket["intensity_factor"]}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "intensity_factor",
        }),
      },
      {
        index: 5,
        title: "Workload",
        metric: `${lastMonthsBucket["workload"]}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "workload",
        }),
      },
      {
        index: 6,
        title: "TSS®",
        metric: `${Number(lastMonthsBucket["tss"])?.toFixed(1)}`,
        icon: TrendImage({
          lastMonthsBucket,
          yearBucket,
          trend: "tss",
        }),
      },
    ],
    [],
  );

  return (
    <Grid
      container
      spacing={2}
      marginTop={1}
      marginBottom={3}
      height={{ xl: "270px", sm: "221px", xs: "350px" }}
    >
      {trendsInfo.map((item, index) => (
        <Grid item xs={6} sm={6} key={index}>
          <Grid container spacing={2} alignItems="center" marginBottom={2}>
            <Grid item>{item.icon}</Grid>
            <Grid
              item
              xs={12}
              sm
              container
              direction="column"
              alignItems="flex-start"
            >
              <Grid item fontSize={{ xs: ".6875rem", xl: ".8125rem" }}>
                {item.title}
              </Grid>
              <Grid item fontSize={".9375rem"} color={"#BC6D29"}>
                {item.title === "Normalised Power®"
                  ? `${Number(item.metric).toFixed(0)} W`
                  : item.metric}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

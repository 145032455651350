import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo, useState, useCallback } from "react";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import {
  useRideSession,
  useSpeciments,
} from "../customPages/SessionViewsPreload";
import { allBikePositions, timeFormatter } from "src/utils/common";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";

const allSpecimentsArray = [
  "Power",
  "Heart Rate",
  "Cadence",
  "Speed",
  "Elevation",
  "Torso Degrees",
] as const;

type SelectSpeciments = typeof allSpecimentsArray[number];

export default function CombinedGraph() {
  const theme = useTheme();

  // const allSpeciments = useSpeciments();

  const allSpeciments = useSpeciments();

  const filteredSpeciments = useMemo(() => {
    return allSpeciments?.filter((_, i) => i % 5 === 0);
  }, [allSpeciments]);

  const session = useRideSession();

  const rideType: "indoor" | "outdoor" | "virtual" = useMemo(
    () =>
      session.ride_types.name === "Indoor"
        ? "indoor"
        : session.ride_types.name === "Outdoor"
        ? "outdoor"
        : "virtual",
    [session],
  );
  const [selectedSpeciments, setSelectedSpeciments] = useState<
    Array<SelectSpeciments>
  >([]);

  const units = useUnits();

  const hideSeriesFromLegend = useCallback(
    (_) => {
      if (filteredSpeciments.length > 0) {
        const positionDiv = document.querySelector(
          'div.apexcharts-legend-series[seriesname="Position"]',
        ) as HTMLElement;
        if (positionDiv !== null) {
          positionDiv.style.display = "none";
        }
        if (session.ride_types.name === "Indoor") {
          const elevationDiv = document.querySelector(
            'div.apexcharts-legend-series[seriesname="Elevation"]',
          ) as HTMLElement;
          if (positionDiv !== elevationDiv) elevationDiv.style.display = "none";
        }
      }
    },
    [session],
  );

  const isMobileScreen = useMediaQuery("(max-width:480px)");

  const chartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        group: "graphs",
        id: "overall",
        animations: {
          enabled: false,
        },
        events: {
          mounted: hideSeriesFromLegend,
          updated: hideSeriesFromLegend,
          legendClick: (_, i) =>
            i !== 0 &&
            setSelectedSpeciments((specs) =>
              specs.indexOf(chartSeries[i].name as any) > -1
                ? specs.filter((val) => val !== chartSeries[i].name)
                : specs.concat([chartSeries[i].name] as any),
            ),
        },
        background: "transparent",
        toolbar: {
          show: true,
          offsetX: -25,
          // offsetY: -20,
          tools: {
            reset: true,
            zoom: "<img/>",
            pan: false,
            selection: false,
            zoomin: false,
            zoomout: false,
            download: false,
          },
        },
      },
      stroke: {
        width: 2,
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      fill: {
        gradient: {
          opacityFrom: 0.7,
          opacityTo: 0.2,
          shade: "medium",
        },
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        enabledOnSeries:
          session.ride_types.name === "Indoor"
            ? [0, 1, 2, 3, 5, 6]
            : [0, 1, 2, 3, 4, 5, 6],
      },
      colors: [
        "#FFBA00",
        "#DD514A",
        "#00B8D9",
        "#E28E54",
        "#9F9EA0",
        "#653780",
        "#55AA55",
      ],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: timeFormatter,
        },
        tickAmount: 5,
        type: "numeric",
      },
      yaxis: [
        {
          show: false,
          opposite: true,
          labels: {
            show: true,
            formatter: (val) => (val ? `${val.toFixed(0)} W` : (val as any)),
          },
        },
        {
          show: false,
          opposite: true,
          labels: {
            show: true,
            formatter: (val) => (val ? `${val.toFixed(0)} bpm` : (val as any)),
          },
        },
        {
          show: false,
          opposite: true,
          labels: {
            show: true,
            style: {
              colors: ["#F77500"],
            },
            formatter: (val) => (val ? `${val.toFixed(0)} rpm` : (val as any)),
          },
        },
        {
          show: session.ride_types.name === "Indoor",
          opposite: false,
          labels: {
            show: true,
            formatter: (val) =>
              val
                ? `${val.toFixed(0)} ${units === "metric" ? "kph" : "mph"}`
                : (val as any),
          },
          title: {
            text: "Speed",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 100,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },

        {
          show: session.ride_types.name !== "Indoor",
          labels: {
            show: true,
            formatter: (val) =>
              !val
                ? (val as any)
                : `${val.toFixed(0)} ${units === "metric" ? "m" : "ft"}`,
          },
          title: {
            text: "Elevation",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        {
          show: false,
          opposite: true,
          labels: {
            show: true,
            formatter: (val) =>
              val ? `${val.toFixed(0)} angle` : (val as any),
          },
        },
      ].concat([
        {
          show: false,
          labels: {
            formatter: (val) => allBikePositions[val],
            style: {
              colors: ["#55AA55"],
            },
          },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: false,
          },
          min: -1,
          max: -1,
        },
      ] as any),
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 5,
        borderColor: theme.colors.alpha.black[10],
      },
    }),
    [],
  );

  const chartSeries = useMemo(
    () =>
      [
        {
          name: "Power",
          data: filteredSpeciments?.map(
            (spec) => [spec.timestamp, spec.power] as any,
          ),
        },
        {
          name: "Heart Rate",
          data: filteredSpeciments?.map(
            (spec) =>
              [spec.timestamp, Number(spec.heartrate?.toFixed(0)) ?? 0] as any,
          ),
        },
        {
          name: "Cadence",
          data: filteredSpeciments?.map(
            (spec) => [spec.timestamp, spec.cadence ?? 0] as any,
          ),
        },
        {
          name: "Speed",
          data: filteredSpeciments?.map(
            (spec) =>
              [
                spec.timestamp,
                units === "metric" ? spec.speed : spec.speed * 0.6213711922,
              ] as any,
          ),
        },
        {
          name: "Elevation",
          data: filteredSpeciments?.map(
            (spec) => [spec.timestamp, spec.elevation] as any,
          ),
        },
        {
          name: "Body Angle",
          data: filteredSpeciments?.map(
            (spec) => [spec.timestamp, spec.torso_degrees] as any,
          ),
        },
      ].concat([
        {
          name: "Position",
          data: filteredSpeciments?.map(
            (spec) =>
              [spec.timestamp, allBikePositions.indexOf(spec.position)] as any,
          ),
        },
      ]),

    [filteredSpeciments, rideType, units, session],
  );
  return (
    <Box
      sx={{
        background: "#201B20",
        borderRadius: ".625rem",
        width: "98%",
        height: { xl: "720px", md: "560px", sm: "400px", xs: "350px" },
      }}
    >
      <Box paddingTop={"1rem"} paddingLeft={"1.375rem"}>
        <Typography variant="h5">Overall</Typography>
      </Box>

      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={isMobileScreen ? "70%" : "90%"}
      />

      {isMobileScreen && (
        <Box sx={{ display: "flex", justifyContent: "center", padding: 1 }}>
          <ScreenRotationIcon fontSize="large" />
        </Box>
      )}
    </Box>
  );
}

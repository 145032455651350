import {
	Box,
	Button,
	Dialog,
	IconButton,
	Popover,
	Typography,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useCallback, useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import { useSnackbar } from "notistack";
import type { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";

export type SubscriptionProps = {
	subscriptionDetails: {
		athlete_id: string;
		customer_id: string | null;
		expire_at: string | null;
		provider: string;
		start_at: string | null;
		status: string;
		subscription_id: string | null;
	};
};
export type FormikViewType = {
	start_date: Date;
	expire_date: Date;
};

export default function AdminDashboardPremiumSubscription(
	props: SubscriptionProps,
) {
	const { enqueueSnackbar } = useSnackbar();
	const supabase = useSupabase();
	const [openDate, setOpenDate] = useState(false);
	const [openStartDate, setOpenStartDate] = useState(false);

	const [open, setOpen] = useState(false);
	const buttonRef = useRef(null);
	const buttonStartDateRef = useRef(null);

	const formik = useFormik<FormikViewType>({
		initialValues: {
			start_date: new Date(props.subscriptionDetails.start_at),
			expire_date: new Date(props.subscriptionDetails.expire_at),
		},
		validationSchema: yup.object().shape({
			start_date: yup.date().required("Start Date is required"),
			expire_date: yup.date().required("Expire Date is required"),
		}),
		onSubmit: (values) => {
			if ("fire" in saveSubscriptionDatesCall) {
				saveSubscriptionDatesCall.fire(async () =>
					saveSubscriptionDatesPromise(values),
				);
			}
		},
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const saveSubscriptionDatesPromise = useCallback(
		(values: FormikViewType) =>
			supabase
				.from("athlete_subscription")
				.update({
					start_at: values.start_date.toISOString(),
					expire_at: values.expire_date.toISOString(),
				})
				.eq("athlete_id", props.subscriptionDetails.athlete_id)
				.then((res) => res.data),
		[supabase],
	);

	const saveSubscriptionDatesCall =
		useAsyncState<SupabaseCall<typeof saveSubscriptionDatesPromise>>();

	const deletePremiumPromise = useCallback(
		() =>
			supabase
				.from("athlete_subscription")
				.update({
					start_at: null,
					expire_at: null,
					status: "incomplete",
				})
				.eq("athlete_id", props.subscriptionDetails.athlete_id)
				.then((res) => res.data),
		[supabase],
	);

	const deletePremiumCall =
		useAsyncState<SupabaseCall<typeof deletePremiumPromise>>();

	useEffect(() => {
		if (isFulfilled(saveSubscriptionDatesCall)) {
			enqueueSnackbar("Dates saved successfully", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "top",
				},
				variant: "success",
				autoHideDuration: 3000,
			});
		}
	}, [saveSubscriptionDatesCall]);

	useEffect(() => {
		if (isFulfilled(deletePremiumCall)) {
			enqueueSnackbar("Premium Removed", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "top",
				},
				variant: "success",
				autoHideDuration: 3000,
			});
		}
	}, [deletePremiumCall]);

	return (
		<>
			<form noValidate onSubmit={formik.handleSubmit}>
				<Box sx={{ gap: "8px", display: "flex", flexDirection: "column" }}>
					<Box flexDirection={"row"} display={"flex"}>
						<Typography alignSelf={"center"}>
							Subscription start date :{" "}
							{
								dayjs(formik.values.start_date).format("DD/MM/YYYY") // '25/01/2019'
							}
						</Typography>
						<IconButton
							onClick={() => setOpenStartDate(!openStartDate)}
							ref={buttonStartDateRef}
						>
							<CalendarMonthIcon sx={{ color: "#BC6D29" }} fontSize="small" />
						</IconButton>
					</Box>
					<Box flexDirection={"row"} display={"flex"}>
						<Typography alignSelf={"center"}>
							Subscription end date :{" "}
							{
								dayjs(formik.values.expire_date).format("DD/MM/YYYY") // '25/01/2019'
							}
						</Typography>
						<IconButton onClick={() => setOpenDate(!openDate)} ref={buttonRef}>
							<CalendarMonthIcon sx={{ color: "#BC6D29" }} fontSize="small" />
						</IconButton>
					</Box>

					<Typography>
						Provider : {props.subscriptionDetails.provider}
					</Typography>

					<Popover
						open={openDate}
						anchorEl={buttonRef.current}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						onClose={(_) => setOpenDate(false)}
						PaperProps={{
							style: {
								background: "none",
							},
						}}
					>
						<Calendar
							onChange={(date) => {
								formik.setFieldTouched("expire_date");
								formik.setFieldValue("expire_date", date);
							}}
							date={new Date(props.subscriptionDetails.expire_at)}
							minDate={new Date(Date.now() + 3600 * 1000 * 24)}
						/>
					</Popover>
					<Popover
						open={openStartDate}
						anchorEl={buttonStartDateRef.current}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						onClose={(_) => setOpenStartDate(false)}
						PaperProps={{
							style: {
								background: "none",
							},
						}}
					>
						<Calendar
							onChange={(date) => {
								formik.setFieldTouched("start_date");
								formik.setFieldValue("start_date", date);
							}}
							date={new Date(props.subscriptionDetails.start_at)}
						/>
					</Popover>
					<Button variant="contained" type="submit">
						Save
					</Button>
					<Button sx={{ mt: "8px" }} onClick={handleClickOpen}>
						Remove Premium
					</Button>
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Remove User as Premium
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Are you sure you want to remove the selected user from premium
								account?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									if ("fire" in deletePremiumCall) {
										deletePremiumCall.fire(async () => deletePremiumPromise());
									}
									handleClose();
								}}
								autoFocus
							>
								Yes
							</Button>
							<Button onClick={handleClose}>No</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</form>
		</>
	);
}

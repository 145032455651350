import { useCallback, useEffect, useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useMediaQuery } from "@mui/material";
import MyAccountView from "./MyAccountView";
import ConnectedAppsView from "src/content/customViews/Setttings/ConnectedAppsView";
import AthleteView from "./AthleteView";
import SubscriptionView from "./SubscriptionView";
import BikeInfoView from "./BikeInfoView";
import { useTitle } from "src/content/customLayouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { WithCache } from "src/contexts/CacheContext";
import { useUser } from "src/components/Authenticated";
import MyCoachView from "./MyCoachView";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import PremiumLogo from "../../../assets/img/views/lock/logoLock.svg";
import LockScreen from "src/content/customComponents/LockScreen";
import SuspenseLoader from "src/components/SuspenseLoader";

type TabValue =
	| "my_account"
	| "my_coach"
	| "athlete"
	| "bikes"
	| "subscription"
	| "connected_apps";

export type SettingsViewProps = {
	tab?: TabValue;
};

export default function SettingsView(props: SettingsViewProps) {
	const user = useUser();
	const supabase = useSupabase();
	const navigate = useNavigate();
	const isMobileScreen = useMediaQuery("(max-width:480px)");
	const [, setTitle] = useTitle();
	const [value, setValue] = useState<TabValue>("my_account");

	useEffect(() => {
		setTitle("Settings");
	}, []);

	const handleChange = useCallback((_, newValue: TabValue) => {
		navigate(`/dashboard/settings/${newValue}`);
	}, []);

	useEffect(() => {
		setValue(props.tab);
	}, [props?.tab]);

	const subscriptionPromise = useCallback(
		() =>
			supabase
				.from("athlete_subscription")
				.select("*")
				.eq("athlete_id", user.id)
				.then((res) => res.data[0]),
		[supabase],
	);

	const subscriptionCall =
		useAsyncState<SupabaseCall<typeof subscriptionPromise>>();

	// Loading effect
	useEffect(() => {
		if (isUnloaded(subscriptionCall)) {
			subscriptionCall.fire(async () => subscriptionPromise());
		}
	}, [subscriptionCall, subscriptionPromise]);
	const isPremium = useMemo(() => {
		if (isFulfilled(subscriptionCall)) {
			return subscriptionCall?.result?.status === "active";
		}
	}, [subscriptionCall]);
	return (
		<>
			<Box
				sx={{
					width: "100%",
					paddingTop: "1.5rem",
					paddingLeft: "1.5625rem",
				}}
			>
				<TabContext value={value}>
					<Box
						sx={{
							borderColor: "divider",
						}}
					>
						<TabList
							onChange={handleChange}
							aria-label="Tab list"
							variant="scrollable"
							sx={{
								"& .MuiTab-root:not(.Mui-selected)": {
									opacity: 0.5,
								},
								minHeight: "1.5rem",
							}}
						>
							<Tab
								label="My account"
								value="my_account"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
							<Tab
								label="My Coach"
								value="my_coach"
								sx={{
									minHeight: 0,
									padding: "10px",
									"& .MuiTab-iconWrapper": { marginLeft: "1px" },
								}}
								icon={
									<img
										src={PremiumLogo}
										alt="lock-logo"
										width={"20px"}
										height={"20px"}
									/>
								}
								iconPosition="end"
							/>
							<Tab
								label="Athlete"
								value="athlete"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
							<Tab
								label="Bikes"
								value="bikes"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
							<Tab
								label="Subscription"
								value="subscription"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
							<Tab
								label="Connected Apps"
								value="connected_apps"
								sx={{ minHeight: 0, padding: "10px" }}
							/>
						</TabList>
					</Box>
					<TabPanel
						value="my_account"
						sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
					>
						<WithCache
							tables={["athlete"]}
							component={(athlete) => (
								<MyAccountView athlete={athlete.athlete[0]} />
							)}
						/>
					</TabPanel>
					<TabPanel
						value="my_coach"
						sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
					>
						{isPremium ? (
							<WithCache
								tables={["athlete", "coach", "country"]}
								component={(full) => (
									<MyCoachView
										athlete={full.athlete[0]}
										coach={full.coach}
										countries={full.country}
									/>
								)}
								pendingComponent={<SuspenseLoader color="#DD4F4A" />}
							/>
						) : (
							<LockScreen
								width="98%"
								description="Connect to a coach to get professional guidance, support and personal training plans./nYour coach will be able to see your sessions, statistics and progress and give you personal feedback."
								useFormattedDescription={true}
							/>
						)}
					</TabPanel>
					<TabPanel
						value="athlete"
						sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
					>
						<WithCache
							tables={[
								"athlete",
								"training_goals",
								"athlete_training_goals",
								"athlete_experience_level",
							]}
							component={(full) => (
								<AthleteView
									athlete={full.athlete[0]}
									athleteTrainingGoals={full.athlete_training_goals}
									experienceLevels={full.athlete_experience_level}
									trainingGoals={full.training_goals}
								/>
							)}
						/>
					</TabPanel>
					<TabPanel value="bikes" sx={{ padding: 0, marginTop: "1rem" }}>
						<WithCache
							tables={["athlete_bikes", "bike_types"]}
							component={(cached) => (
								<BikeInfoView
									athleteBikes={cached.athlete_bikes.filter(
										(bike) => bike.athlete_id === user.id && !bike.soft_deleted,
									)}
									bikeTypes={cached.bike_types}
								/>
							)}
						/>
					</TabPanel>
					<TabPanel value="subscription" sx={{ padding: 0, marginTop: "1rem" }}>
						<WithCache
							tables={["athlete_subscription"]}
							component={(cached) => (
								<SubscriptionView
									athleteSubscription={cached.athlete_subscription[0]}
								/>
							)}
						/>
					</TabPanel>
					<TabPanel
						value="connected_apps"
						sx={{ padding: 0, marginTop: "1rem" }}
					>
						<WithCache
							tables={["athlete_bikes"]}
							component={(cached) => (
								<ConnectedAppsView
									athleteBikes={cached.athlete_bikes.filter(
										(bike) => bike.athlete_id === user.id && !bike.soft_deleted,
									)}
								/>
							)}
						/>
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
}

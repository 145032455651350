import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Modal, Tab, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTitle } from "src/content/customLayouts/DashboardLayout";
import WorkoutsDataGrid from "./WorkoutsDataGrid";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import logo from "../../../assets/img/views/lock/logoLock.svg";

type TabValue = "training-plans" | "workouts";

export type LibraryTabProps = {
  tab?: TabValue;
};

export default function LibraryView(props: LibraryTabProps) {
  const [, setTitle] = useTitle();
  const supabase = useSupabase();
  const user = useUser();
  const [open, setOpen] = useState(false);

  const subscriptionPromise = useCallback(
    () =>
      supabase
        .from("athlete_subscription")
        .select("*")
        .eq("athlete_id", user.id)
        .then((res) => res.data[0]),
    [supabase],
  );

  const subscriptionCall =
    useAsyncState<SupabaseCall<typeof subscriptionPromise>>();

  // Loading effect
  useEffect(() => {
    if (isUnloaded(subscriptionCall)) {
      subscriptionCall.fire(async () => subscriptionPromise());
    }
  }, [subscriptionCall, subscriptionPromise]);
  const isPremium = useMemo(() => {
    if (isFulfilled(subscriptionCall)) {
      return subscriptionCall?.result?.status === "active";
    }
  }, [subscriptionCall]);

  useEffect(() => {
    setTitle("Library");
  }, []);

  const [value, setValue] = useState<TabValue>("training-plans");
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const handleChange = useCallback((_, newValue: TabValue) => {
    navigate(`/dashboard/library/${newValue}`);
  }, []);

  useEffect(() => {
    setValue(props.tab);
  }, [props?.tab]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
        paddingLeft: "25px",
        paddingBottom: "20px",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            paddingRight: "20px",
            flexDirection: isMobileScreen ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            aria-label="Tab list"
            sx={{
              "& .MuiTab-root:not(.Mui-selected)": {
                opacity: 0.5,
              },
              minHeight: "1.5rem",
              borderBottom: 1,
              borderColor: "rgba(188, 109, 41, 0.5)",
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#BC6D29",
              },
            }}
          >
            <Tab
              label="Training Plans"
              value="training-plans"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
            <Tab
              label="Workouts"
              value="workouts"
              sx={{
                minHeight: 0,
                padding: "10px",
              }}
            />
          </TabList>

          {value === "workouts" && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: isMobileScreen ? "" : "flex-end",
                marginTop: isMobileScreen ? "0.5rem" : "",
              }}
            >
              <Button
                variant="contained"
                onClick={(_) =>
                  isPremium ? navigate("add_workout") : handleOpen()
                }
                size={isMobileScreen ? "small" : "medium"}
                fullWidth
              >
                +ADD NEW
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 4,
                    height: "40%",
                    width: "40%",
                    backgroundColor: "rgba(32, 27, 32, 0.7)",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(12px)",
                    padding: "20px",
                  }}
                >
                  <img
                    src={logo}
                    alt="lock-logo"
                    height={"100px"}
                    width={"100px"}
                  />
                  <Typography variant="h3" fontWeight={700}>
                    This is a premium feature.
                  </Typography>
                  <Typography
                    alignSelf={"center"}
                    maxWidth={"60%"}
                    textAlign={"center"}
                  >
                    Analytics help transform data into actionable insights,
                    allowing users to track performance, identify trends, and
                    make informed decisions. By visualizing key metrics,
                    analytics tools enable deeper understanding and optimization
                    across various areas, driving improved efficiency and
                    success.
                  </Typography>
                  <Button variant="outlined" sx={{ alignContent: "flex-end" }}  onClick={() => navigate("/dashboard/settings/subscription")}>
                    Upgrade To Premium
                  </Button>
                </Box>
              </Modal>
            </Box>
          )}
        </Box>
        <TabPanel
          value="training-plans"
          sx={{ padding: 0, minHeight: 0, marginTop: "0.6rem" }}
        >
          <Outlet />
        </TabPanel>

        <TabPanel
          value="workouts"
          sx={{ padding: 0, minHeight: 0, marginTop: "1rem" }}
        >
          <WorkoutsDataGrid />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

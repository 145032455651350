import { Box, Button, Typography } from "@mui/material";
import logo from "../../assets/img/views/lock/logoLock.svg";
import { useNavigate } from "react-router-dom";
import React from "react";

export type LockScreenProps = {
  title?: string;
  titleColor?: string;
  description?: string;
  useFormattedDescription?: boolean;
};

const formatDescription = (text) => {
  return text.split("/n").map((line, index) => (
    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export default function LockScreenZones({
  title,
  titleColor,
  description,
  useFormattedDescription = false,
}: LockScreenProps) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: { xs: "0.2em", xl: "0.5em" },
          paddingLeft: "1em",
          borderRadius: "10px",
          alignItems: "center",
          gap: { xl: "12px", xs: "4px" },
          width: "65%",
          "@media (max-width: 1200px)": {
            width: "95%",
          },
          background: "linear-gradient(125deg, #3D343E 11.69%, #272727 55.18%)",
          backdropFilter: "blur(6px)",
          border: "2px solid #DD4F4A",
          marginTop: "15px",
        }}
      >
        <Typography variant="h3" alignSelf={"flex-start"} color={titleColor}>
          {title}
        </Typography>
        <Box
          style={{
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <img src={logo} alt="lock-logo" height={"100px"} width={"100px"} />
          <Typography variant="h3" fontWeight={700}>
            Premium Feature
          </Typography>
          <Typography
            alignSelf={"center"}
            textAlign={"center"}
            maxWidth={"90%"}
          >
            {useFormattedDescription
              ? formatDescription(description)
              : description}
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{
            alignSelf: "center",
            marginTop: "auto",
            mb: "24px",
          }}
          onClick={() => navigate("/dashboard/settings/subscription")}
        >
          Upgrade To Premium
        </Button>
      </Box>
    </>
  );
}

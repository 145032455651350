import { Box, Button, Typography } from "@mui/material";
import logo from "../../../assets/img/views/lock/logoLock.svg";
import { useNavigate } from "react-router-dom";

export default function LockScreenWidget() {
	const navigate = useNavigate();
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					paddingTop: { xs: "0.2em", xl: "0.5em" },
					paddingLeft: "1em",
					borderRadius: "10px",
					alignItems: "center",
					gap: { xl: "12px", xs: "4px" },
					height: "100%",
					background: "linear-gradient(125deg, #3D343E 11.69%, #272727 55.18%)",
					backdropFilter: "blur(6px)",
					border: "2px solid #DD4F4A",
				}}
			>
				<Typography variant="h5" alignSelf={"flex-start"}>
					POSITION PROGRESS
				</Typography>
				<img src={logo} alt="lock-logo" height={"100px"} width={"100px"} />
				<Typography variant="h3" fontWeight={700}>
					Premium Feature
				</Typography>
				<Typography alignSelf={"center"} textAlign={"center"} maxWidth={"80%"}>
					Gain insights into how much time you spend in each cycling position
					every month, whether you are riding Indoor or Outdoor, on Road or TT
					bikes.
				</Typography>
				<Button
					variant="outlined"
					sx={{
						alignSelf: "center",
						marginTop: "auto",
						mb: "20px",
					}}
					onClick={() => navigate("/dashboard/settings/subscription")}
				>
					Upgrade To Premium
				</Button>
			</Box>
		</>
	);
}

import {
  Box,
  Tooltip,
  type TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";

import infoicon from "../../assets/img/views/profile-setup/info-icon.svg";
import { useSubscription } from "../customPages/SessionViewsPreload";
import LockScreenPerformanceIndexCard from "./LockScreenPerformanceIndexCard";
import PremiumLogo from "../../assets/img/views/lock/premiumVersionLogo.svg";


export type PerformanceIndexCardProps = {
  index: number;
  title: string;
  description: string;
  metric: string | number;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function PerformanceIndexCard(props: PerformanceIndexCardProps) {
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const isPremium = useSubscription();
  return (
    <>
      {!isPremium && (props.index === 8 || props.index === 9) ? (
        <LockScreenPerformanceIndexCard
          title={props.index === 8 ? "Ideal Positions" : "Speed efficiency"}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "#2C262D",
            width: {
              xs: "90%",
              sm: props.index === 9 ? "82.5%" : "40%",
              md: "30%",
            },
            padding: "2em",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position:"relative"
          }}
        >
          {(props.index === 8 || props.index === 9) && (
						<img
							src={PremiumLogo}
							alt="lock-logo"
							height="28px"
							width="28px"
							style={{
								zIndex: 1,
								position: "absolute",
								top: -13,
								right: -14,
							}}
						/>
					)}
          <Box sx={{ display: "flex", gap: 1, marginBottom: 1 }}>
            <Typography
              variant={isMobileScreen ? "h4" : "h3"}
              sx={{
                color: "#BC6D29",
                fontWeight: 700,
              }}
            >
              {props.title}
            </Typography>
            <CustomWidthTooltip
              title={props.description}
              placement="right"
              arrow
            >
              <img src={infoicon} alt="info_icon" width="15" height="15" />
            </CustomWidthTooltip>
          </Box>
          <Typography
            variant="h2"
            sx={{
              color: "#A19D9A",
            }}
          >
            {props.metric || "-"}{" "}
            {props.title === "Normalised Power®"
              ? "W"
              : props.title === "Ideal Positions"
              ? "%"
              : ""}
          </Typography>
        </Box>
      )}
    </>
  );
}

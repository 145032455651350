import {
  Box,
  Card,
  Grid,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import type { ApexOptions } from "apexcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import AdminStatsCard from "./AdminStatsCard";
import AdminAnalyticsTitles from "./AdminAnalyticsTitles";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import AdminTrendsGraph from "./AdminTrendsGraph";

export default function AdminAnalyticsView() {
  const theme = useTheme();

  const supabase = useSupabase();

  const athletePromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select("country_code,gender,dob,experience_level,height,weight,ftp")
        .then((res) => res.data),
    [supabase],
  );

  const storedAthleteCall =
    useAsyncState<SupabaseCall<typeof athletePromise>>();

  useEffect(() => {
    if (isUnloaded(storedAthleteCall)) {
      storedAthleteCall.fire(async () => athletePromise());
    }
  }, [storedAthleteCall, athletePromise]);

  const sessionsPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select(
          "id,athlete_id,recording_name,total_distance,total_time,average_power,average_speed,average_cadence,elevation_gain,average_heart_rate,date,athlete:athlete_id(name)",
        )
        .then((res) => {
          const totalDistanceMax = res.data.sort(
            (a, b) => b.total_distance - a.total_distance,
          )[0];
          const totalTimeMax = res.data.sort(
            (a, b) => b.total_time - a.total_time,
          )[0];
          const averagePowerMax = res.data.sort(
            (a, b) => b.average_power - a.average_power,
          )[0];
          const averageSpeedMax = res.data.sort(
            (a, b) => b.average_speed - a.average_speed,
          )[0];
          const averageCadenceMax = res.data.sort(
            (a, b) => b.average_cadence - a.average_cadence,
          )[0];
          const averageHeartRateMax = res.data.sort(
            (a, b) => b.average_heart_rate - a.average_heart_rate,
          )[0];
          const elevationGainMax = res.data.sort(
            (a, b) => b.elevation_gain - a.elevation_gain,
          )[0];

          return {
            totalDistanceMax,
            totalTimeMax,
            averagePowerMax,
            averageSpeedMax,
            averageCadenceMax,
            averageHeartRateMax,
            elevationGainMax,
          };
        }),
    [supabase],
  );

  const storedSessionCall =
    useAsyncState<SupabaseCall<typeof sessionsPromise>>();

  useEffect(() => {
    if (isUnloaded(storedSessionCall)) {
      storedSessionCall.fire(async () => sessionsPromise());
    }
  }, [storedSessionCall, sessionsPromise]);

  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);

  const [chartSeriesGender, setChartSeriesGender] = useState([]);
  const [chartSeriesCountry, setChartSeriesCountry] = useState([]);
  const [chartSeriesAge, setChartSeriesAge] = useState([]);
  const [chartSeriesLevel, setChartSeriesLevel] = useState([]);
  const [chartSeriesHeight, setChartSeriesHeight] = useState([]);
  const [chartSeriesWeight, setChartSeriesWeight] = useState([]);
  const [chartSeriesFTP, setChartSeriesFTP] = useState([]);

  const chartOptionsGender: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "pie",
        fontFamily: "Arial, sans-serif",
      },
      colors: ["#4e79a7", "#f28e2b"],
      labels: ["Male", "Female"],
      legend: {
        labels: {
          colors: "white",
        },
      },
    }),
    [],
  );

  const chartOptionsLevel: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "pie",
        fontFamily: "Arial, sans-serif",
        toolbar: { show: false },
      },
      colors: ["#4e79a7", "#f28e2b", "#DD4F4A", "#EFC09F"],
      labels: ["Beginner", "Intermediate", "Advanced", "Expert"],
      legend: {
        labels: {
          colors: "white",
        },
      },
    }),
    [],
  );

  const chartOptionsAge: ApexOptions = useMemo(() => {
    const ageGroups = [18, 31, 41, 51, 61, 71];
    const xCategories = ageGroups.map((ageGroup, index, array) => {
      const lowerBound = ageGroup;
      const upperBound = index === array.length - 1 ? 70 : array[index + 1] - 1;
      return `[${lowerBound}-${upperBound}]`;
    });

    return {
      chart: {
        type: "bar",
        toolbar: { show: false },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: xCategories,
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: (val) => val?.toFixed(0),
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "5%",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} users`,
          title: { formatter: (seriesName) => "Age Distribution : " },
        },
        x: {
          show: false,
        },
      },
    };
  }, []);

  const chartOptionsCountry: ApexOptions = useMemo(
    () => ({
      chart: {
        type: "bar",
        fontFamily: "Arial, sans-serif",
        toolbar: { show: false },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: chartSeriesCountry.map((data) => data.x),
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: (val) => val?.toFixed(0),
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
          colors: {
            ranges: [
              {
                from: 0,
                to: 1000,
                color: "#f28e2b",
              },
              {
                from: 1001,
                to: 2000,
                color: "#4e79a7",
              },
            ],
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} users`,
          title: { formatter: (seriesName) => "Country: " },
        },
        x: {
          show: false,
        },
      },
    }),
    [chartSeriesCountry],
  );

  const chartOptionsHeight: ApexOptions = useMemo(() => {
    const heightGroup = [1.5, 1.6, 1.7, 1.8, 1.9, 2.0];
    const xCategories = heightGroup.map((heightGroup, index, array) => {
      const lowerBound = heightGroup;
      const upperBound = index === array.length - 1 ? 2.0 : array[index + 1];
      return `[${lowerBound}-${upperBound}]`;
    });

    return {
      chart: {
        type: "bar",
        toolbar: { show: false },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: xCategories,
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: (val) => val?.toFixed(0),
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} users`,
          title: { formatter: (seriesName) => "Height Distribution : " },
        },
        x: {
          show: false,
        },
      },
    };
  }, []);

  const chartOptionsWeight: ApexOptions = useMemo(() => {
    const weightGroup = [60, 70, 80, 90, 100, 110];
    const xCategories = weightGroup.map((weightGroup, index, array) => {
      const lowerBound = weightGroup;
      const upperBound =
        index === array.length - 1 ? 110 : array[index + 1] - 1;
      return `[${lowerBound}-${upperBound}]`;
    });

    return {
      chart: {
        type: "bar",
        toolbar: { show: false },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: xCategories,
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: (val) => val?.toFixed(0),
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} users`,
          title: { formatter: (seriesName) => "Width Distribution : " },
        },
        x: {
          show: false,
        },
      },
    };
  }, []);

  const chartOptionsFTP: ApexOptions = useMemo(() => {
    const ftpGroup = [200, 210, 220, 230, 240, 250, 260, 270];
    const xCategories = ftpGroup.map((ftpGroup, index, array) => {
      const lowerBound = ftpGroup;
      const upperBound =
        index === array.length - 1 ? 270 : array[index + 1] - 1;
      return `[${lowerBound}-${upperBound}]`;
    });

    return {
      chart: {
        type: "bar",
        toolbar: { show: false },
        background: "transparent",
      },
      theme: {
        mode: theme.palette.mode,
      },
      xaxis: {
        categories: xCategories,
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
        },
        axisTicks: { show: false },
      },
      yaxis: {
        labels: {
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: (val) => val?.toFixed(0),
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} users`,
          title: { formatter: (seriesName) => "FTP Distribution : " },
        },
        x: {
          show: false,
        },
      },
    };
  }, []);

  const experienceLevelMap = {
    1: "Beginner",
    2: "Intermediate",
    3: "Advanced",
    4: "Expert",
  };

  useEffect(() => {
    if (isFulfilled(storedAthleteCall)) {
      // Genders
      const genders = storedAthleteCall.result.map((stat) =>
        stat.gender ? "Male" : "Female",
      );
      const maleCount = genders.filter((gender) => gender === "Male").length;
      const femaleCount = genders.filter(
        (gender) => gender === "Female",
      ).length;
      setChartSeriesGender([maleCount, femaleCount]);

      // Experience Levels
      const experienceLevels = storedAthleteCall.result.map(
        (stat) => experienceLevelMap[stat.experience_level],
      );
      const beginnerCount = experienceLevels.filter(
        (level) => level === "Beginner",
      ).length;
      const intermediateCount = experienceLevels.filter(
        (level) => level === "Intermediate",
      ).length;
      const advancedCount = experienceLevels.filter(
        (level) => level === "Advanced",
      ).length;
      const expertCount = experienceLevels.filter(
        (level) => level === "Expert",
      ).length;
      setChartSeriesLevel([
        beginnerCount,
        intermediateCount,
        advancedCount,
        expertCount,
      ]);

      //Ages

      const currentYear = new Date().getFullYear();
      const ages = storedAthleteCall.result.map((stat) => {
        const birthYear = new Date(stat.dob).getFullYear();
        return currentYear - birthYear;
      });
      setChartSeriesAge(ages);

      // Categorize ages into groups
      const ageGroups = [18, 31, 41, 51, 61, 71]; // Updated maximum age to 70
      const ageData = Array(ageGroups.length - 1).fill(0);
      ages.forEach((age) => {
        for (let i = 0; i < ageGroups.length - 1; i++) {
          if (age >= ageGroups[i] && age < ageGroups[i + 1]) {
            ageData[i]++;
            break;
          }
        }
      });

      setChartSeriesAge(ageData);

      // Heights
      const heightGroup = [1.5, 1.6, 1.7, 1.8, 1.9, 2.0];
      const heightData = Array(heightGroup.length - 1).fill(0);
      const heights = storedAthleteCall.result.map((stat) => stat.height);

      heights.forEach((height) => {
        for (let i = 0; i < heightGroup.length - 1; i++) {
          if (height >= heightGroup[i] && height < heightGroup[i + 1]) {
            heightData[i]++;
            break;
          }
        }
      });
      setChartSeriesHeight(heightData);

      // Weights
      const weightGroup = [60, 70, 80, 90, 100, 110];
      const weightData = Array(weightGroup.length - 1).fill(0);
      const weights = storedAthleteCall.result.map((stat) => stat.weight);

      weights.forEach((weight) => {
        for (let i = 0; i < weightGroup.length - 1; i++) {
          if (weight >= weightGroup[i] && weight < weightGroup[i + 1]) {
            weightData[i]++;
            break;
          }
        }
      });
      setChartSeriesWeight(weightData);

      // FTP
      const ftpGroup = [200, 210, 220, 230, 240, 250, 260, 270];
      const ftpData = Array(ftpGroup.length - 1).fill(0);
      const ftps = storedAthleteCall.result.map((stat) => stat.ftp);

      ftps.forEach((ftp) => {
        for (let i = 0; i < ftpGroup.length - 1; i++) {
          if (ftp >= ftpGroup[i] && ftp < ftpGroup[i + 1]) {
            ftpData[i]++;
            break;
          }
        }
      });
      setChartSeriesFTP(ftpData);

      //Countries
      const countryCodes = storedAthleteCall.result.map(
        (stat) => stat.country_code,
      );
      const uniqueCountryCodes = Array.from(new Set(countryCodes)); // Get unique country codes
      const countryCodeData = uniqueCountryCodes.map((code) => {
        return {
          x: code,
          y: countryCodes.filter((c) => c === code).length,
        };
      });
      setChartSeriesCountry(countryCodeData);
    }
  }, [storedAthleteCall]);

  const statInfo = useMemo(
    () => [
      {
        label: "Active Users",
        icon: <PersonIcon />,
        name: "active users",
      },

      {
        label: "Active Athletes",
        icon: <PersonIcon />,
        name: "active athletes",
      },
      {
        label: "Darefore Athletes",
        icon: <PersonIcon />,
        name: "active darefore",
      },
      {
        label: "New Users",
        icon: <PersonAddIcon />,
        name: "new users",
      },
      {
        label: "Total Sessions",
        icon: <LoginIcon />,
        name: "total sessions",
      },
      {
        label: "Total Logins",
        icon: <LoginIcon />,
        name: "total logins",
      },
    ],
    [],
  );

  const tableMaxData = useMemo(
    () => [
      isFulfilled(storedSessionCall) && {
        label: "Max Avg Power",
        value:
          storedSessionCall?.result.averagePowerMax?.average_power?.toFixed(0),
        metric: "W",
        athlete: storedSessionCall?.result.averagePowerMax?.athlete["name"],
        date: new Date(
          storedSessionCall?.result.averagePowerMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Avg Speed",
        value:
          storedSessionCall.result.averageSpeedMax?.average_speed?.toFixed(0),
        metric: "km/h",
        athlete: storedSessionCall.result.averageSpeedMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.averageSpeedMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Avg Heart Rate",
        value:
          storedSessionCall.result.averageHeartRateMax?.average_heart_rate?.toFixed(
            0,
          ),
        metric: "bpm",
        athlete: storedSessionCall.result.averageHeartRateMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.averageHeartRateMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Avg Cadence",
        value:
          storedSessionCall.result.averageCadenceMax?.average_cadence?.toFixed(
            0,
          ),
        metric: "rpm",
        athlete: storedSessionCall.result.averageCadenceMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.averageCadenceMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Total Distance",
        value:
          storedSessionCall.result.totalDistanceMax?.total_distance?.toFixed(0),
        metric: "km",
        athlete: storedSessionCall.result.totalDistanceMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.totalDistanceMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Total Duration",
        value: timeFormatter(storedSessionCall.result.totalTimeMax?.total_time),
        metric: "s",
        athlete: storedSessionCall.result.totalTimeMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.totalTimeMax?.date,
        ).toLocaleDateString("en-GB"),
      },
      isFulfilled(storedSessionCall) && {
        label: "Max Elevation Gain",
        value:
          storedSessionCall.result.elevationGainMax?.elevation_gain?.toFixed(0),
        metric: "m",
        athlete: storedSessionCall.result.elevationGainMax?.athlete["name"],
        date: new Date(
          storedSessionCall.result.elevationGainMax?.date,
        ).toLocaleDateString("en-GB"),
      },
    ],
    [storedSessionCall],
  );

  const allUsersPromise = useCallback(
    () => supabase.functions.invoke("list-all-users"),
    [supabase],
  );

  const allUsersCall = useAsyncState<any>();

  useEffect(() => {
    if (isUnloaded(allUsersCall)) {
      allUsersCall.fire(async () => allUsersPromise());
    }
  }, [allUsersPromise, allUsersCall]);

  // Admin Related
  const adminPromise = useCallback(
    () =>
      supabase
        .from("admin")
        .select()
        .throwOnError()
        .then((res) => res.data),
    [supabase],
  );

  const adminCall = useAsyncState<SupabaseCall<typeof adminPromise>>();

  useEffect(() => {
    if (isUnloaded(adminCall)) {
      adminCall.fire(async () => adminPromise());
    }
  }, [adminCall, adminPromise]);

  const allUsers = useMemo(() => {
    if (isFulfilled(allUsersCall) && isFulfilled(adminCall)) {
      return allUsersCall.result.data.data.filter((user) =>
        adminCall.result.every((admin) => admin.user_id !== user.id),
      );
    }
  }, [allUsersCall, adminCall]);

  const auditLogs = useMemo(() => {
    if (isFulfilled(allUsersCall) && isFulfilled(adminCall)) {
      return allUsersCall.result.data.auditData.filter((log) =>
        adminCall.result.every(
          (admin) => admin.user_id !== log.payload.actor_id,
        ),
      );
    }
  }, [allUsersCall, adminCall]);

  return (
    <>
      {allUsers?.length > 0 && (
        <>
          <Grid
            container
            spacing={2}
            padding={"2em"}
            sx={{
              justifyContent: "center",
            }}
          >
            {statInfo.map((info, index) => (
              <Grid item key={index}>
                <AdminStatsCard
                  label={info.label}
                  icon={info.icon}
                  promiseName={
                    info.name as
                      | "active users"
                      | "active athletes"
                      | "active darefore"
                      | "new users"
                      | "total logins"
                      | "total sessions"
                  }
                  allUsers={allUsers}
                  auditLogs={auditLogs}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AdminAnalyticsTitles label={"User Activity Trends"} />
            <Box width={"50%"} mb={"1rem"}>
              <Card
                sx={{
                  background: "#262026",
                  padding: "1em",
                  borderRadius: "15px",
                }}
              >
                <Typography variant="h4">Activity Trends</Typography>
                <AdminTrendsGraph allUsers={allUsers} auditLogs={auditLogs}/>
              </Card>
            </Box>
            <AdminAnalyticsTitles label={"Geographic User Analysis"} />
            <Box width={"50%"} mb={"1rem"}>
              <Card
                sx={{
                  background: "#262026",
                  padding: "1em",
                  borderRadius: "15px",
                }}
              >
                <Typography variant="h4">Country</Typography>
                <Chart
                  options={chartOptionsCountry}
                  series={[{ data: chartSeriesCountry }]}
                  type="bar"
                  height={320}
                />
              </Card>
            </Box>

            <AdminAnalyticsTitles label={"User Demographics"} />

            <Box
              width={"100%"}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Gender</Typography>
                  <Chart
                    options={chartOptionsGender}
                    series={chartSeriesGender}
                    type="pie"
                    height={320}
                  />
                </Card>
              </Box>

              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Age Distribution</Typography>
                  <Chart
                    options={chartOptionsAge}
                    series={[{ data: chartSeriesAge }]}
                    type="bar"
                    height={320}
                  />
                </Card>
              </Box>
            </Box>
            <AdminAnalyticsTitles label={"Athlete Demographics"} />
            <Box
              width={"100%"}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Experience Level</Typography>
                  <Chart
                    options={chartOptionsLevel}
                    series={chartSeriesLevel}
                    type="pie"
                    height={320}
                  />
                </Card>
              </Box>

              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Height Distribution</Typography>
                  <Chart
                    options={chartOptionsHeight}
                    series={[{ data: chartSeriesHeight }]}
                    type="bar"
                    height={320}
                  />
                </Card>
              </Box>
            </Box>

            <Box
              width={"100%"}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Weight Distribution</Typography>
                  <Chart
                    options={chartOptionsWeight}
                    series={[{ data: chartSeriesWeight }]}
                    type="bar"
                    height={320}
                  />
                </Card>
              </Box>

              <Box width={"45%"} mb={"1rem"}>
                <Card
                  sx={{
                    background: "#262026",
                    padding: "1em",
                    borderRadius: "15px",
                  }}
                >
                  <Typography variant="h4">Average FTP</Typography>
                  <Chart
                    options={chartOptionsFTP}
                    series={[{ data: chartSeriesFTP }]}
                    type="bar"
                    height={320}
                  />
                </Card>
              </Box>
            </Box>

            <AdminAnalyticsTitles label={"Platform Records"} />

            <TableContainer
              component={Paper}
              sx={{
                width: "90%",
                margin: "1rem",
                background: "#262026",
                borderRadius: "10px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color={"#BC6D29"}>
                        Record
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color={"#BC6D29"}>
                        Value
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color={"#BC6D29"}>
                        Athlete
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color={"#BC6D29"}>
                        Date
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableMaxData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1">{item.label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {item.value} {item.metric}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.athlete}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.date}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
}

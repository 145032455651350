import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useUser } from "src/components/Authenticated";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function SubscriptionForm ({onClick}: any) {
    const theme = useTheme();
    const breakpoint = theme.breakpoints.down("md");
    const user = useUser();
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        if (!stripe) {
            return;
        }
        setIsLoading(true);

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setError(submitError.message)
            setIsLoading(false)
            return;
        }
        const {error, confirmationToken} = await stripe.createConfirmationToken({
            elements,
            params: {
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: user.user_metadata.country
                        }
                    }
                }
            },
        });
    
        if(error) {
            setError(error.message)
            setIsLoading(false)
            return;
        }
        const res = await onClick(confirmationToken)
        if(res.error) {
            const errorMessage = await res.error.context.json()
            setError(errorMessage.error)
            setIsLoading(false)
            return;
        }
        setIsSuccess(true)
    }

    if(isSuccess) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: '2rem'
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "#342C35",
                        borderRadius: "6px",
                        paddingX: "3.5em",
                        paddingY: "2em",
                        marginTop: "1rem",
                        width: "25%",
                        gap: 3,
                        [breakpoint]: {
                            width: "85%",
                            margin: "1rem auto 0",
                        },
                    }}
                >
                    <CheckCircleIcon sx={{fontSize: 100}} color="success"/>
                    <Typography
                        variant="h2"
                        marginBottom={"1rem"}
                        sx={{ fontWeight: "400", alignSelf: "center" }}
                    >
                        Success!
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => window.location.reload()}
                    >
                        Reload App!
                    </Button>
                </Box>
            </Box>
        )
    }
    
    return (
        <form 
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
                marginTop: '2rem'
            }}
            >
            <PaymentElement 
                options={{
                fields: {
                    billingDetails: {
                    address: {
                        country: 'never'
                    }
                    }
                }
                }}
            />
            <Button variant='contained' type="submit" disabled={isLoading}>
                {
                    isLoading ? (
                        <CircularProgress size={20} disableShrink thickness={3} style={{color: '#BC6D29'}}/>
                    ) : (
                        'Subscribe'
                    )
                }
            </Button>
            {
                error !== "" && (
                    <Typography variant="coach400">{error}</Typography>
                )
            }
        </form>
    )
}
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import EditIcon from "../../../assets/img/layout/coach/Library/EditIcon.svg";
import { useUser } from "src/components/Authenticated";
import type { TableRow } from "src/contexts/CacheContext";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import CoachChangePassword from "../customComponents/CoachChangePassword";
import type { CoachSettingsAccountType } from "./CoachSettingsView";
import type { FormikProps } from "formik";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import { useSupabase } from "src/contexts/SupabaseContext";

export type CoachSettingsAccountProps = {
	coach: TableRow<"coach">;
	formik: FormikProps<CoachSettingsAccountType>;
	imageFile: File;
	setImageFile: Dispatch<SetStateAction<File>>;
};

export default function CoachSettingsAccount(props: CoachSettingsAccountProps) {
	const user = useUser();
	const inputNameRef = useRef(null);
	const supabase = useSupabase();
	const [openModal, setOpenModal] = useState(false);
	const inputImageRef = useRef<HTMLInputElement>();

	const handleClose = useCallback(() => {
		setOpenModal(false);
	}, []);

	function handleImageChange(e) {
		if (!e.target.files[0]) return;
		props.setImageFile(e.target.files[0]);
	}

	const handleImageRemove = useCallback(async () => {
		const { data:list } = await supabase.storage.from('avatars').list(`${props.coach.user_id}`);
		const filesToRemove = list.map((x) => `${props.coach.user_id}/${x.name}`);
		 await supabase.storage.from('avatars').remove(filesToRemove);   
		 await supabase
		  .from("coach")
		  .update({ image: null })
		  .eq("user_id", props.coach.user_id);
	  }, [supabase, props.imageFile]);


	const avatar = useMemo(() => {
		if (props.imageFile) {
			return URL.createObjectURL(props.imageFile);
		}
		if (props.coach.image) {
			return supabase.storage.from("avatars").getPublicUrl(props.coach.image, {
				transform: {
					width: 150,
					height: 150,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 150,
			radius: 55,
			seed: props.coach.name,
		}).toDataUriSync();
	}, [props.imageFile, props.coach]);

	return (
		<>
			<Box
				sx={{
					width: "98%",
					padding: "1.5em",
					background: "#201B20",
					borderRadius: "10px",
				}}
			>
				<Box
					sx={{
						width: "98%",
						background: "#201B20",
						borderRadius: "10px",
						paddingY: "1em",
						minHeight: "70vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<Typography sx={{ fontSize: ".8125rem" }}>
							PERSONAL INFORMATION
						</Typography>

						{/* Image Box */}
						<Box
							sx={{
								borderRadius: "6px",
								paddingX: "3em",
								justifyContent: "center",
								alignItems: "center",
								gap: "1rem",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<img
								src={avatar}
								alt="avatar"
								style={{
									borderRadius: "50%",
									width: "150px",
									height: "150px",
									objectFit: "cover",
								}}
							/>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									gap: 2,
								}}
							>
								<input
									ref={inputImageRef}
									style={{ display: "none" }}
									type="file"
									onChange={handleImageChange}
									accept="image/png, image/jpeg"
								/>
								<Button
									variant="outlined"
									size="small"
									onClick={() => inputImageRef.current.click()}
								>
									Upload
								</Button>
								<Button
									variant="outlined"
									size="small"
									onClick={handleImageRemove}
								>
									Remove
								</Button>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-evenly",
								padding: "2em",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									gap: 2,
								}}
							>
								<TextField
									InputProps={{
										readOnly: true,
									}}
									value={user.email}
									sx={{
										border: "1px solid #64605D",
										borderRadius: "4px",
										borderRightColor: "#A19D9A",
										"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
											borderColor: "#DD4F4A !important",
										},
									}}
								/>

								<FormControl>
									<TextField
										name="name"
										onBlur={props.formik.handleBlur}
										value={props.formik.values.name}
										onChange={props.formik.handleChange}
										error={
											props.formik.touched.name &&
											Boolean(props.formik.errors.name)
										}
										helperText={
											props.formik.touched.name && props.formik.errors.name
										}
										inputRef={inputNameRef}
										sx={{
											borderRadius: "4px",
											borderRightColor: "#A19D9A",
											"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
												borderColor: "#DD4F4A !important",
											},
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() => inputNameRef.current.focus()}
													>
														<img src={EditIcon} aria-label="edit" />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</FormControl>

								<FormControl>
									<Select
										id="gender"
										name="gender"
										value={props.formik.values.gender}
										onChange={props.formik.handleChange}
										sx={{
											color: "#F2F2F2",
											"& .MuiSvgIcon-root": {
												color: "#DD4F4A",
											},
											"&.MuiOutlinedInput-root": {
												"& fieldset": {
													borderColor: "#64605D",
												},
												"&:hover fieldset": {
													borderColor: "#64605D",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#64605D",
												},
											},
										}}
										MenuProps={{
											PaperProps: {
												style: {
													border: ".0625rem solid #64605D",
												},
												sx: {
													"&& .Mui-selected": {
														backgroundColor: "#64605D",
													},
												},
											},
											MenuListProps: {
												disablePadding: true,
											},
										}}
									>
										<MenuItem value="male">Male</MenuItem>
										<MenuItem value="female">Female</MenuItem>
									</Select>
								</FormControl>
								<FormControl
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
									}}
								>
									<RadioGroup
										row
										onChange={props.formik.handleChange}
										value={props.formik.values.units}
										name="units"
										sx={{
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<FormControlLabel
											value="metric"
											control={<Radio sx={{ color: "#DD4F4A !important" }} />}
											label={<span style={{ fontSize: "12px" }}>Metric</span>}
										/>
										<FormControlLabel
											value="imperial"
											control={<Radio sx={{ color: "#DD4F4A !important" }} />}
											label={<span style={{ fontSize: "12px" }}>Imperial</span>}
										/>
									</RadioGroup>
								</FormControl>
								<Button
									variant="coachVariant2"
									fullWidth
									onClick={() => setOpenModal(true)}
									sx={{ width: { xs: "11rem", sm: "20.5rem" } }}
								>
									Change Password
								</Button>
							</Box>
						</Box>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Button variant="coachVariant" type="submit">
							Save
						</Button>
					</Box>
				</Box>
			</Box>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<>
					<CoachChangePassword onClose={handleClose} />
				</>
			</Modal>
		</>
	);
}

import { useState, useCallback, useEffect } from "react";
import {
	Button,
	Modal,
	Box,
	Typography,
	FormControl,
	TextField,
	Skeleton,
	IconButton,
} from "@mui/material";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useSnackbar } from "notistack";
import { useUser } from "src/components/Authenticated";
import LoadingAth from "../../../assets/img/layout/coach/MyAthletes/LoadingAth.svg";
import CloseIconAvatar from "../../../assets/img/layout/coach/MyAthletes/CloseIconAvatar.svg";
import SuccessConnect from "../../../assets/img/layout/coach/MyAthletes/SuccessConnect.svg";

export default function ConnectAthlete() {
	const user = useUser();
	const supabase = useSupabase();
	const { enqueueSnackbar } = useSnackbar();
	const [athleteEmail, setAthleteEmail] = useState("");
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendConnectionNotificationPromise = useCallback(
		async (email: string) => {
			const { data } = await supabase.rpc("get_athlete_user_id_by_email", {
				email,
			});
			if (data.length > 0) {
				return supabase.from("notifications").insert({
					type: "Connection Request",
					content: {
						description: `Coach ${user.user_metadata.name} (${user.email}) wants to connect with you.`,
						coach_id: user.id,
					},
					user_id: data[0].id,
				});
			}
		},
		[supabase, user],
	);

	const sendConnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof sendConnectionNotificationPromise>>();

	const coachConnectionPromise = useCallback(
		async (email: string) => {
			const { data } = await supabase.rpc("get_athlete_user_id_by_email", {
				email,
			});
			if (data.length > 0) {
				return supabase.from("coach_athletes").insert({
					coach_id: user.id,
					athlete_id: data[0].id,
					from_user: false,
					status: "Pending",
				});
			}
		},
		[supabase, user],
	);

	const coachConnectionCall =
		useAsyncState<SupabaseCall<typeof coachConnectionPromise>>();

	useEffect(() => {
		if (isFulfilled(sendConnectionNotificationCall)) {
			if (sendConnectionNotificationCall.result) {
				enqueueSnackbar("Connection request sent!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "success",
					autoHideDuration: 2000,
				});
				handleClose();
			} else {
				enqueueSnackbar("No athlete found!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	}, [sendConnectionNotificationCall]);

	return (
		<>
			<Box>
				<Button variant="coachVariant" size="small" onClick={handleOpen}>
					Connect to Athlete
				</Button>
				<Modal open={open} onClose={handleClose}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							bgcolor: "#2A252B",
							border: "3px solid #DD4F4A",
							p: 8,
						}}
					>
						<Typography variant="coach700" component="h1">
							Connect to Athlete
						</Typography>
						<FormControl sx={{ mt: 6 }}>
							<TextField
								name="email"
								label="Athlete Email"
								onChange={(e) => setAthleteEmail(e.target.value)}
							/>
						</FormControl>
						<Box sx={{ mt: 4 }}>
							<Button
								onClick={handleClose}
								variant="outlined"
								size="small"
								sx={{ width: "155px", mr: 2 }}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									if ("fire" in sendConnectionNotificationCall) {
										sendConnectionNotificationCall.fire(async () =>
											sendConnectionNotificationPromise(athleteEmail),
										);
									}
									if ("fire" in coachConnectionCall) {
										coachConnectionCall.fire(async () =>
											coachConnectionPromise(athleteEmail),
										);
									}
								}}
								variant="coachVariant"
								size="small"
								sx={{ width: "155px" }}
							>
								Send
							</Button>
						</Box>
					</Box>
				</Modal>
			</Box>

			{/* <Box>
        <Button variant="coachVariant" size="small" onClick={handleOpen}>
          Connect to athlete
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#2A252B",
              border: "3px solid #DD4F4A",
              p: 8,
            }}
          >
            <Typography variant="coach700" component="h1">
              Find Athletes
            </Typography>
            <FormControl sx={{ mt: 4 }}>
              <TextField name="email" label="Athletes Email" />
            </FormControl>
            <Box
              sx={{
                mt: 6,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={LoadingAth}
                alt="filter-down-arrow"
                style={{ width: "200px" }}
              />
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                sx={{ width: "155px", mt: 6 }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box> */}

			{/* <Box>
        <Button variant="coachVariant" size="small" onClick={handleOpen}>
          Connect to athlete
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#2A252B",
              border: "3px solid #DD4F4A",
              p: 8,
            }}
          >
            <Typography variant="coach700" component="h1">
              Find Athletes
            </Typography>
            <FormControl sx={{ mt: 4 }}>
              <TextField name="email" label="Athletes Email" />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#2C262D",
                borderRadius: "4px",
                border: "1px solid #DD4F4A",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Box sx={{ display: "flex", gap: 2, alignItems: "center",padding:2 }}>
                <Skeleton variant="circular" width={80} height={80} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography fontSize={".875rem"}>John Doe</Typography>
                  <Typography fontSize={".75rem"}>Coach</Typography>
                  <Typography fontSize={".625rem"} sx={{ opacity: 0.5 }}>
                    Cyprus,Paphos
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignSelf: "flex-start" }}>
                <IconButton>
                  <img src={CloseIconAvatar} alt="close" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: 6 }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                sx={{ width: "155px", mr: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleClose}
                variant="coachVariant"
                size="small"
                sx={{ width: "155px" }}
              >
                Find
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box> */}

			{/* <Box>
        <Button variant="coachVariant" size="small" onClick={handleOpen}>
          Connect to athlete
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#2A252B",
              border: "3px solid #DD4F4A",
              p: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={SuccessConnect}
              alt="successConnect"
              style={{ width: "120px" }}
            />

            <Typography variant="coach700" component="h2" mt={4}>
              Your request has been sent.
            </Typography>
            <Box sx={{ mt: 6 }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="small"
                sx={{ width: "155px" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box> */}
		</>
	);
}
